// Types
import { PaymentType } from 'types/Payment.types';

// Config
import paymentTypeConstants from '../../constants/paymentTypeConstants';

function isSavedPaymentCard(paymentType?: PaymentType): boolean | undefined {
  return paymentType?.includes(paymentTypeConstants.SAVED_PAYMENT_CARD);
}

export default isSavedPaymentCard;
