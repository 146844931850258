// Types
import { RootState } from 'types/RootState.types';

// Config
import makeGiftOptionsReducer from './giftOptionsHigherOrderReducer';
import { APPLY_GIFT_CARD } from '../../../constants/actionConstants';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';
import isZeroAmount from '../../../utils/payment/isZeroAmount';

export const INITIAL_STATE = {
  isLoading: false,
  formSubmitFailed: false,
  errorMessage: undefined,
};

export default makeGiftOptionsReducer({
  INITIAL_STATE,
  ACTION_NAME: APPLY_GIFT_CARD,
});

export const giftCardsSelector = (state: RootState, context: string | undefined) => {
  const { orderForm: { payments = [] } = {} } = state ?? {};

  return payments
    .filter((payment) => payment.type === paymentTypeConstants.GIFT_CARD)
    .filter((payment) => !payment.isRemoved || (payment.isRemoved && payment.removalContext === context))
    .reduce((acc, payment) => {
      if (isZeroAmount(payment.amount)) {
        return {
          ...acc,
          unusedGiftCards: [
            ...(acc.unusedGiftCards || []),
            {
              ...payment.details,
              id: payment.id,
            },
          ],
        };
      }

      return {
        ...acc,
        usedGiftCards: [
          ...(acc.usedGiftCards || []),
          {
            ...payment,
            cardNumber: payment.details?.cardNumber,
            pin: payment.details?.pin,
          },
        ],
      };
    }, {});
};
