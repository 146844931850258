import { Component, ErrorInfo } from 'react';

// Redux
import { connect } from 'react-redux';
import { triggerLazyComponentWithSuspenseFailure } from '../../redux/actions/error/errorActions';

// Types
import { ErrorSafeguardProps, ErrorSafeguardState } from './ErrorSafeguard.types';

class ErrorSafeguard extends Component<ErrorSafeguardProps, ErrorSafeguardState> {
  state: ErrorSafeguardState = {
    errorCount: 0,
    hasError: false,
  };

  static getDerivedStateFromError(): ErrorSafeguardState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, _errorInfo: ErrorInfo) {
    const { errorCount = 0 } = this.state;
    const {
      maxRetries = 2,
      retryLoadingComponent,
      triggerLazyComponentWithSuspenseFailure,
    } = this.props;

    if (errorCount < maxRetries) {
      this.setState((prevState) => {
        const { errorCount: prevErrorCount = 0 } = prevState;
        return ({ errorCount: prevErrorCount + 1, hasError: false });
      }, () => {
        const { errorCount: currentErrorCount = 0 } = this.state;
        retryLoadingComponent(currentErrorCount);
      });
    } else {
      triggerLazyComponentWithSuspenseFailure({ error });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children, fallbackUi = null } = this.props;

    if (hasError) {
      return fallbackUi;
    }

    return children;
  }
}

export function mapDispatchToProps() {
  return { triggerLazyComponentWithSuspenseFailure };
}

export default connect(null, mapDispatchToProps())(ErrorSafeguard);
