import { ReactNode, createContext, useContext, useState, useEffect } from 'react';

// Types
import { WindowSizeContextProps } from './WindowSizeContext.types';

// Config
import appConstants from '../../constants/appConstants';

export const WindowSizeContext = createContext<WindowSizeContextProps>({
  desktopResolution: false,
  isDesktop: false,
  isLargeDesktop: false,
  isModalSmall: false,
  isSmallDesktop: false,
  sidebarIsVisible: false,
});

export const WindowSizeProvider = ({ children }: { children: ReactNode }) => {
  const [windowSize, setWindowSize] = useState<WindowSizeContextProps>({
    desktopResolution: false,
    isDesktop: false,
    isLargeDesktop: false,
    isModalSmall: false,
    isSmallDesktop: false,
    sidebarIsVisible: false,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        desktopResolution: window.matchMedia(`(min-width: ${appConstants.DESKTOP_BREAKPOINT})`).matches,
        isDesktop: window.matchMedia(`(min-width: ${appConstants.MOBILE_BREAKPOINT})`).matches,
        isLargeDesktop: window.matchMedia(`(min-width: ${appConstants.DESKTOP_LG_BREAKPOINT})`).matches,
        isModalSmall: window.matchMedia(`(max-width: ${appConstants.MODAL_SMALL})`).matches,
        isSmallDesktop: window.matchMedia(`(min-width: ${appConstants.DESKTOP_SM_BREAKPOINT})`).matches,
        sidebarIsVisible: window.matchMedia(`(min-width: ${appConstants.SIDEBAR_MIN_BREAKPOINT})`).matches,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <WindowSizeContext.Provider value={windowSize}>
      {children}
    </WindowSizeContext.Provider>
  );
};

export const useWindowSize = () => useContext(WindowSizeContext);
