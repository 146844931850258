import { addDays, differenceInDays, format } from 'date-fns';

// Types
import { DeliveryProps } from 'types/Delivery.types';
import { RootState } from 'types/RootState.types';

// Config
import featureConstants from '../../constants/featureConstants';
import { isFeatureActive } from '../../redux/reducers/config/configReducer';

const daysToDelayBy = 8;

export const getOutletFromDate = ({ state, id, fromDate }: { fromDate?: string; id?: string; state: RootState }) => {
  if (isFeatureActive(state, featureConstants.OUTLET_DELIVERY_DATES)) {
    const { delivery: { deliveries = [] } = {} } = state;

    const isOutletDelivery = deliveries.find((delivery) => delivery.id === id)?.outlet;
    if (!fromDate && isOutletDelivery) {
      const outletFromDate = format(addDays(new Date(), daysToDelayBy), 'YYYY-MM-DD');
      return outletFromDate;
    }
  }

  return undefined;
};

export const isOutletPrevFromTooEarly = ({ delivery, state }: { delivery?: DeliveryProps; state: RootState }) => {
  if (isFeatureActive(state, featureConstants.OUTLET_DELIVERY_DATES) && delivery?.outlet) {
    const method = delivery?.methods?.[delivery.selectedMethod || 0] ?? {};

    const { fulfilmentOffers: { navs: { prevFrom = '' } = {} } = {} } = method;

    if (prevFrom) {
      const earliestOutletDate = addDays(new Date(), daysToDelayBy);
      const prevFromDate = Date.parse(prevFrom);
      const prevFromEarlierThanOutlet = differenceInDays(earliestOutletDate, prevFromDate) > 0;

      return prevFromEarlierThanOutlet;
    }
  }

  return false;
};
