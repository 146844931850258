/* eslint-disable css-modules/no-unused-class */

// Types
import { LoadingOrderItemsProps } from './LoadingOrderItems.types';

// Components
import LoadingElement from '../loading-element';
import Container from '../../container';

// Styles
import styles from '../loading-wrapper.scss';

const LoadingOrderItems = (
  { className, loadingItems = 1 }: LoadingOrderItemsProps,
) => Array.from({ length: loadingItems }, (_, index) => (
  <Container
    key={`loading-order-items__${index}`}
    borderBottom="grey-15"
    className={className}
    data-index="order-summary-item"
    flex
    marginX="3"
    paddingY="3"
    testId="loading-order-items"
  >
    <Container className={styles.orderItemImage} marginRight="2">
      <LoadingElement />
    </Container>
    <Container className={styles.orderItemText} width="full">
      {Array.from({ length: 6 }, (_, childIndex) => (
        <Container key={`loading-order-items__item__${childIndex}`} marginBottom="1">
          <LoadingElement
            type={childIndex === 0 || childIndex === 5 ? 'dark' : 'light'}
          />
        </Container>
      ))}
    </Container>
  </Container>
));

export default LoadingOrderItems;
