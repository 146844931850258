import { createElement } from 'react';

// Design System
import Input from 'jl-design-system/elements/input/Input';

// Types
import { InputWithProgressProps } from './InputWithProgress.types';

// Config
import PROGRESS_STATE from '../../../../constants/progressStateConstants';

// Components
import CircleLoader from '../../../circle-loader';

// Styles
import styles from './input-with-loading.scss';

const { NONE } = PROGRESS_STATE;

const InputWithProgress = ({
  inputElement: InputElement = Input,
  progress = NONE,
  ...props
}: InputWithProgressProps) => (
  <div className={`${styles.root} ${progress !== NONE && styles.padding}`}>
    {InputElement
      ? createElement(InputElement, { ...props })
      : <Input {...props} />}
    <CircleLoader
      className={styles.spinner}
      progress={progress}
    />
  </div>
);

export default InputWithProgress;
