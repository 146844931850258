import { ReactNode } from 'react';

// Types
import { DeliveryProps } from 'types/Delivery.types';
import { ProductProps } from 'types/Product.types';
import { RootState } from 'types/RootState.types';

// Config
import deliveryConstants from '../../constants/deliveryConstants';

export function shouldShowPersonalisationHelpText({
  isChildItem,
  orderComplete,
  personalised,
  state,
}: { isChildItem?: boolean; orderComplete?: boolean; personalised?: boolean; state?: RootState }): boolean {
  if (!personalised) return false;

  if (orderComplete || state?.app?.orderComplete) return false;

  if (isChildItem) return false;

  return true;
}

export function deliveryContainsPersonalisedItems(delivery: DeliveryProps): boolean | undefined {
  return delivery.type === deliveryConstants.SUPPLIER_DIRECT && delivery?.items?.some(item => item.personalised);
}

export function getPersonalisedItems({
  deliveries = [],
  flowersIsDisabled = false,
}: {
  deliveries: DeliveryProps[];
  flowersIsDisabled?: boolean;
}): ProductProps[] {
  const sdDeliveries = deliveries.filter(
    (delivery) => delivery.type === deliveryConstants.SUPPLIER_DIRECT || (!flowersIsDisabled && delivery.isFlowerGift),
  );

  return sdDeliveries.reduce<ProductProps[]>((acc, delivery) => {
    if (!delivery.items) return acc;

    const updatedItems = delivery.items.map((item) => ({
      ...item,
      ...(!flowersIsDisabled && delivery.isFlowerGift && { isFlower: true }),
    }));

    return [...acc, ...updatedItems.filter((item) => item.personalised || (!flowersIsDisabled && item.isFlower))];
  }, []);
}

export function modifyDeliveryMethodText(additionalInformation: string | ReactNode): ReactNode {
  return (
    <>
      <p>{ additionalInformation }</p>
      <p>Once you’ve placed your order, please email your personalisation details to the supplier.</p>
      <p>Details on how to do this can be found on the product details page or the order confirmation page.</p>
      <p>
        If this is not received within three working days,
        a representative will contact you by phone to discuss your order.
      </p>
    </>
  );
}
