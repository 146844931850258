import { Component } from 'react';
import PropTypes from 'prop-types';
// jl-design-system
import AddressForm from 'jl-design-system/components/address-form/AddressForm';
import { Message } from 'jl-design-system/components/message/Message';
import { DynamicForm } from 'jl-design-system/components/form/Form';
import { SecondaryButton, UnderlinedButton } from 'jl-design-system/elements/button/Button';
import { getFormInitialValuesFromAddress } from 'jl-design-system/utils/address/address';
// lodash
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
// redux
import { connect } from 'react-redux';
import { resetAllOtherForms } from '../../redux/actions/form/formActions';
// config
import getInvalidAddressFormConfig from '../../utils/form/configs/invalidAddress';
import { BILLING_ADDRESS_FORM_ID } from '../../utils/form/configs/billingAddress';

// Components
import Container from '../container';

// Styles
import styles from './invalid-address-form.scss';

export class InvalidAddressForm extends Component {

  static propTypes = {
    addressBook: PropTypes.arrayOf(PropTypes.object),
    addressFormId: PropTypes.string,
    configExtras: PropTypes.shape({}),
    destroyOnUnmount: PropTypes.bool,
    getConfig: PropTypes.func,
    // eslint-disable-next-line
    hasFormErrors: PropTypes.bool,
    initialValues: PropTypes.shape({}),
    international: PropTypes.bool,
    internationalMode: PropTypes.bool,
    isBillingAddress: PropTypes.bool,
    isPaypalExpress: PropTypes.bool,
    isSignedIn: PropTypes.bool,
    maxAddressLines: PropTypes.number,
    messageBody: PropTypes.string,
    onChangeAddress: PropTypes.func,
    onCreateAddress: PropTypes.func,
    onUpdateAddress: PropTypes.func,
    onUpdateAddressOverride: PropTypes.func,
    resetAllOtherForms: PropTypes.func,
    savedAddress: PropTypes.shape({
      address: PropTypes.shape({
        address1: PropTypes.string,
        address2: PropTypes.string,
        address3: PropTypes.string,
        address4: PropTypes.string,
        countryCode: PropTypes.string,
        countyStateOrProvince: PropTypes.string,
        postcode: PropTypes.string,
        town: PropTypes.string,
      }),
      addressee: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        title: PropTypes.string,
      }),
      id: PropTypes.string,
      phoneNumber: PropTypes.string,
    }),
    showChangeAddressButton: PropTypes.bool,
    // eslint-disable-next-line
    submitFailed: PropTypes.bool,
    submitFailedCount: PropTypes.number,
  };

  static defaultProps = {
    addressBook: [],
    addressFormId: '',
    configExtras: {},
    destroyOnUnmount: false,
    getConfig: window.defaultFunc,
    hasFormErrors: true,
    initialValues: {},
    international: false,
    internationalMode: false,
    isBillingAddress: false,
    isPaypalExpress: false,
    isSignedIn: false,
    maxAddressLines: 3,
    messageBody: '',
    onChangeAddress: window.defaultFunc,
    onCreateAddress: window.defaultFunc,
    onUpdateAddress: window.defaultFunc,
    onUpdateAddressOverride: undefined,
    resetAllOtherForms: window.defaultFunc,
    showChangeAddressButton: false,
    submitFailed: false,
    submitFailedCount: 0,
  };

  static getDerivedStateFromProps(props, state) {

    if (state.showErrorMessage) {
      if ((!state.oldPropsSubmitFailed && props.submitFailed) ||
        (state.oldPropsFormErrors && !props.hasFormErrors)) {
        return {
          showErrorMessage: false,
        };
      }
    }

    return null;
  }

  constructor() {
    super();

    this.state = {
      showErrorMessage: true,
      oldPropsFormErrors: undefined,
      oldPropsSubmitFailed: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      resetAllOtherForms,
      submitFailedCount,
      addressFormId,
    } = this.props;

    // user keeps clicking update address but form has errors
    const hasSubmitFailed = submitFailedCount > prevProps.submitFailedCount;
    if (hasSubmitFailed) {
      // reset all the other mounted forms so we only error this form
      if (addressFormId === BILLING_ADDRESS_FORM_ID) {
        resetAllOtherForms([addressFormId]);
      }
    }
  }

  onAddressFormSubmit = (values) => {

    const { onUpdateAddressOverride, addressFormId } = this.props;

    if (onUpdateAddressOverride) {
      return onUpdateAddressOverride(values);
    }

    const {
      onCreateAddress,
      onUpdateAddress,
      addressBook,
      savedAddress,
      isSignedIn,
    } = this.props;

    const selectedAddress = addressBook?.find(addressRecord => addressRecord.id === values.id) || {};

    const transformedValues = {
      ...values,
      id: values.id,
      contact: selectedAddress.contact,
    };

    if (!savedAddress?.id && isSignedIn) {
      return onCreateAddress({
        formValues: transformedValues,
        formId: addressFormId,
      });
    }

    return onUpdateAddress({
      formValues: transformedValues,
      formId: addressFormId,
    });
  };

  render() {
    const {
      addressFormId,
      configExtras,
      destroyOnUnmount,
      getConfig,
      initialValues,
      international,
      internationalMode,
      isBillingAddress,
      isPaypalExpress,
      maxAddressLines,
      messageBody,
      onChangeAddress,
      showChangeAddressButton,
    } = this.props;

    const { showErrorMessage } = this.state;

    const changeAddressButton = {
      extra: true,
      name: 'incomplete-address-change-address-button',
      component: isBillingAddress ? UnderlinedButton : SecondaryButton,
      props: {
        'data-testid': 'delivery-address-change-button',
        children: 'Change address',
        onClick: onChangeAddress,
        className: isBillingAddress ? styles.changeAddressButtonBilling : undefined,
      },
    };

    const transformedConfigExtras = {
      ...configExtras,
      changeAddressButton: showChangeAddressButton ? changeAddressButton : undefined,
    };

    return (
      <div className={styles.container}>
        {showErrorMessage && (
          <Container marginBottom="2" testId="invalid-address-error">
            <Message
              body={messageBody}
              title="Please update the highlighted fields below"
              type="error"
            />
          </Container>
        )}

        <AddressForm
          configExtras={transformedConfigExtras}
          data-testid="invalid-address-form"
          destroyOnUnmount={destroyOnUnmount}
          formId={addressFormId}
          getConfig={getInvalidAddressFormConfig({
            getConfig,
            editMode: true,
            initialValues,
            isPaypalExpress,
          })}
          initialValues={initialValues}
          international={international}
          internationalMode={internationalMode}
          maxAddressLines={maxAddressLines}
          multiFormValidation
          onFormSubmit={this.onAddressFormSubmit}
          postcodeSearchFormVisible={false}
          showErrorsOnInit
          useV2Message
        />
      </div>
    );
  }
}

export function mapStateToProps(state, ownProps) {
  const formId = ownProps.addressFormId;
  const form = get(state.form, formId, {});
  const syncErrors = form.syncErrors;
  const submitFailed = form.submitFailed;

  const savedAddress = ownProps.savedAddress || {};
  const initValues = getFormInitialValuesFromAddress(savedAddress);

  const initialValues = {
    countryCode: 'GB',
    ...initValues,
  };

  return {
    hasFormErrors: !isEmpty(syncErrors),
    isSignedIn: state.user?.isSignedIn,
    submitFailed,
    submitFailedCount: DynamicForm.formSubmitValidationFailures[formId],
    initialValues,
    internationalMode: ownProps.international,
  };
}

export function mapDispatchToProps() {
  return {
    resetAllOtherForms,
  };
}

export default connect((state, ownProps) => mapStateToProps(state, ownProps), mapDispatchToProps())(InvalidAddressForm);
