import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

// Design System
import env from 'jl-design-system/utils/env/env';
import redirect from 'jl-design-system/utils/redirect/redirect';

// Config
import { getIsApplicationSelector } from '../redux/reducers/app/appSelector';
import { clearStorage } from '../utils/storage/storage';

export const handbackToUrl = (event?: Event, url?: string) => {
  if (event) {
    event.preventDefault();
    url = url || (event.currentTarget as HTMLAnchorElement).getAttribute('href') || undefined;
  }

  clearStorage({ local: false });

  if (url) {
    redirect(url);
  }
};

export const getProcessedHandbackUrl = (url: string, isApp: boolean) => {
  if (!url) return '/';

  const urlSansProtocol = url.substring(url.indexOf('//') + 2);
  const pathIndex = urlSansProtocol.indexOf('/');
  const path = pathIndex >= 0 ? urlSansProtocol.substring(pathIndex) : '/';

  if (!isApp) {
    if (url.includes('/basket') && env.isClientLocal) {
      return '/';
    }
    return path === '/' ? `${window.publicJLSiteDomain}/#homepage` : `${window.publicJLSiteDomain}${path}`;
  }

  return url;
};

export const useHandbackToUrl = (url?: string) => {
  const isApps = useSelector(getIsApplicationSelector);

  const handbackUrl = useMemo(() => getProcessedHandbackUrl(url ?? '', isApps), [isApps, url]);

  const onClick = useCallback((event?: Event) => handbackToUrl(event, handbackUrl), [handbackUrl]);

  return { to: handbackUrl, onClick };
};
