import { useSelector } from 'react-redux';
import cx from 'classnames';

// Types
import { OrderTotalsProps } from './OrderTotals.types';

// Config
import { orderTotalsConfirmationPagePropsState, orderTotalsOrderFormState } from './OrderTotals.state';
import { isZeroAmount } from '../../utils';

// Components
import BodyText from '../body-text';
import Container from '../container';
import Heading from '../heading';
import LoadingWrapper from '../loading-wrapper';
import PricePromise from '../price-promise';

// Styles
import styles from './order-totals.scss';

const OrderTotals = ({
  confirmationPage = false,
  containerWide = false,
  editBasketModal = false,
  isDeliveryPage = false,
  sidebarView = false,
}: OrderTotalsProps) => {
  const {
    amounts,
    applyRewardsApiCallActive,
    isGiftReceiptOrder,
    isClickCollectOrder,
    promotionalCode = {},
    pricePromiseFeatureActive,
  } = useSelector(confirmationPage ? orderTotalsConfirmationPagePropsState : orderTotalsOrderFormState);

  const {
    basketTotal,
    deliveryCharge = '',
    grandTotal,
    outstandingBalance,
    partnerDiscountTotal,
    paymentTotals: {
      giftCard = '',
      giftVoucher = '',
    } = {},
    rewardsDiscountTotal,
  } = amounts ?? {};

  const promoCodeRemoved = promotionalCode?.isRemoved ?? false;
  const promotionalCodeAmount = promotionalCode?.amount;
  const showGiftCard = !confirmationPage && !isZeroAmount(giftCard);
  const showGiftVoucher = !confirmationPage && !isZeroAmount(giftVoucher);

  const config:
  {
    className?: string;
    condition: any;
    content: {
      component?: any;
      isLoading?: boolean;
      loadingClassName?: string;
      tag?: string;
      text?: string;
      type?: string;
    }[];
    testId: string;
  }[] = [
    {
      condition: !sidebarView && partnerDiscountTotal,
      testId: 'order-totals-partner-discount-total',
      content: [
        { text: 'Partner discount' },
        { text: `-${partnerDiscountTotal}` },
      ],
    },
    {
      condition: true,
      testId: 'order-totals-basket-total',
      content: [
        { text: 'Basket total' },
        { component: Heading, text: basketTotal, type: 'xs' },
      ],
    },
    {
      condition: isDeliveryPage,
      testId: 'order-totals-delivery-charge-info',
      content: [
        { text: '(Excluding delivery charge)' },
      ],
    },
    {
      condition: !isDeliveryPage,
      testId: 'order-totals-delivery',
      content: [
        { text: isClickCollectOrder ? 'Collection charge' : 'Delivery charge' },
        { text: `${deliveryCharge}` },
      ],
    },
    {
      condition: !promoCodeRemoved && !isZeroAmount(promotionalCodeAmount),
      testId: 'order-totals-promotional-code',
      content: [
        { text: 'Promo code' },
        { text: `-${promotionalCodeAmount}` },
      ],
    },
    {
      condition: rewardsDiscountTotal,
      testId: 'order-totals-reward',
      content: [
        { text: 'Reward applied' },
        {
          loadingClassName: styles.rewardsDiscountTotalLoading,
          text: `-${rewardsDiscountTotal}`,
          isLoading: applyRewardsApiCallActive,
        },
      ],
    },
    {
      condition: !isDeliveryPage && (showGiftCard || showGiftVoucher),
      testId: 'order-totals-subtotal',
      content: [
        { text: 'Total' },
        { text: `${grandTotal}` },
      ],
    },
    {
      condition: showGiftCard,
      testId: 'order-totals-gift-card',
      content: [
        { text: 'Gift cards applied' },
        { text: `-${giftCard}` },
      ],
    },
    {
      condition: showGiftVoucher,
      testId: 'order-totals-gift-voucher',
      content: [
        { text: 'Gift vouchers applied' },
        { text: `-${giftVoucher}` },
      ],
    },
    {
      className: styles.outstandingBalance,
      condition: !isDeliveryPage,
      testId: 'order-totals-balance',
      content: [
        { text: confirmationPage ? 'Order total paid' : 'Order total', type: 'l' },
        { component: Heading, text: confirmationPage ? grandTotal : outstandingBalance, type: 's' },
      ],
    },
    {
      condition: pricePromiseFeatureActive && !sidebarView && !editBasketModal,
      className: 'flex justify-end',
      content: [
        { component: PricePromise },
      ],
      testId: 'price-promise',
    },
    {
      className: cx(styles.giftMessagePricesInfo, {
        [styles.sidebar]: sidebarView,
      }),
      condition: isGiftReceiptOrder,
      testId: 'gift-message-prices-info',
      content: [
        { tag: 'p', text: 'Prices will not be shown on the gift receipt' },
      ],
    },
  ];

  return (
    <Container
      className={cx(styles.container, {
        [styles.isDeliveryPage]: isDeliveryPage,
        [styles.sidebar]: sidebarView,
        [styles.containerWide]: containerWide,
        [styles.editBasketModal]: editBasketModal,
      })}
      tag="section"
      testId="order-totals-container"
    >
      {config.map(
        ({ className, condition, testId, content }) => condition && (
          <Container key={testId} className={className} testId={testId}>
            {content.map(({ component: Component = BodyText, isLoading, loadingClassName, tag = 'span', text, type }, index) => {
              const key = `${testId}_${index}`;

              return (
                <Component key={key} tag={tag} type={type}>
                  <LoadingWrapper className={loadingClassName} isLoading={isLoading}>
                    {text}
                  </LoadingWrapper>
                </Component>
              );
            })}
          </Container>
        ),
      )}
    </Container>
  );
};

export default OrderTotals;
