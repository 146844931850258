import { addDays, format, isEqual } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { AnyAction } from 'redux';

// Types
import { CollectionPointProps } from 'types/CollectionPoint.types';
import { ClickAndCollectState } from 'types/RootState.types';

// Config
import getFilteredCollectionPoints from './getFilteredCollectionPoints';
import {
  SET_COLLECTION_POINT_VIEW_TYPE,
  CLEAR_COLLECTION_POINT_SELECTION,
  FIND_COLLECTION_POINT,
  LOGIN_RESET,
  SELECT_COLLECTION_POINT,
  SET_FIND_COLLECTION_POINT_ERROR,
  GET_COLLECTION_POINT_DETAILS,
  NEW_COLLECTION_POINT_SEARCH,
  HAND_OVER_RESET,
  PUT_CC_DELIVERY_DETAILS,
  PUT_CC_DELIVERY_DETAILS_SILENTLY,
  REDUX_FORM_SET_SUBMIT_FAILED,
  SET_ACTIVE_MAP_MARKER,
  SET_ACTIVE_MODAL_MAP_MARKER,
  ROUTER_LOCATION_CHANGE,
  GET_COLLECTION_POINT_DATES,
  SET_CLICK_COLLECT_COUNTDOWN_VISIBILITY,
  SET_CLICK_COLLECT_CUT_OFF_PASSED,
  SET_CLICK_COLLECT_CUT_OFF_PASSED_MODAL_VISIBILITY,
  SET_SELECTED_DELIVERY_CHOICE_ID,
  SHOW_REMOVE_COLLECTION_POINT_OVERLAY,
  HIDE_REMOVE_COLLECTION_POINT_OVERLAY,
  DELETE_COLLECTION_POINT,
  POST_DELIVERY_PAGE,
  GET_DELIVERY_PAGE,
  SET_GEO_LOCATION_SEARCH_ACTIVE_STATUS,
  SILENTLY_SELECT_COLLECTION_DATE,
  GET_PAYMENT_PAGE,
  INIT_PAYMENT_PAGE,
  GOOGLE_MAPS_SCRIPT_LOADED,
  GOOGLE_MAPS_SCRIPT_LOADING,
  GET_SAVED_COLLECTION_POINTS,
  REDUX_HYDRATE,
  GET_ORDER_CONFIRMATION_PAGE,
} from '../../../constants/actionConstants';
import { VIEW_TYPE_LIST, VIEW_TYPE_MAP, STORE_SELECTED, STORE_DETAILS } from '../../../constants/clickCollectConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import deliveryConstants from '../../../constants/deliveryConstants';
import { getNonGlobalError } from '../../../utils/error/parseError';
import { isZeroAmount } from '../../../utils';
import { isCollectPlusRelatedType } from '../../../utils/collection/getCollectPlusRelatedStores';
import promotePartnershipStore from '../../../utils/collection/promotePartnershipStore';

export const INITIAL_STATE = {
  activeViewType: VIEW_TYPE_LIST,
  collectionPointSearchTerm: '',
  collectionPointSearchResults: [],
  collectionPointsOwners: [],
  totalCollectionPoints: 0,
  unfilteredCollectionPoints: [],
  getCollectionPointDetailsCallActive: false,
  collectionPointSearchActive: false,
  collectionPointSearchComplete: false,
  findCollectionPointError: undefined,
  filteredCollectionPoints: undefined,
  activeModalMapMarker: undefined,
  storeDetailsModalOpen: false,
  confirmedCollectionPoint: undefined,
  selectedCollectionPoint: undefined,
  proceedingToPayment: false,
  activeMapMarker: undefined,
  confirmedCollectorDetails: undefined,
  isInitialSelection: undefined,
  selectedCollectionTime: undefined,
  countdownVisible: undefined,
  suggestedNewCollectionDate: {},
  cutOffTimeForSelectedStore: undefined,
  cutOffTimeForAllStores: undefined,
  showCutOffPassedModal: false,
  deleteCollectionPointApiCallActive: false,
  showCollectionPointDeleteSuccessMessage: false,
  showCollectionPointDeleteFailedMessage: false,
  collectionPointIdToBeRemoved: undefined,
  removeCollectionPointOverlayVisible: false,
  geoLocationSearchActive: false,
  savedCollectionPoints: [],
  newCollectionPointSearch: false,
};

export const mapSavedCollectionPoints = (points: CollectionPointProps[]) => (
  points.map(point => ({
    ...point,
    isSaved: true,
  }))
);

function findStoreById(state: ClickAndCollectState, id?: string) {
  return [
    ...[...state.unfilteredCollectionPoints ?? [], ...state.savedCollectionPoints ?? []],
    state.selectedCollectionPoint,
  ].find(storeItem => storeItem?.id === id);
}

export default function clickAndCollectReducer(state: ClickAndCollectState = INITIAL_STATE, action: AnyAction) {
  switch (action?.type) {
    case HAND_OVER_RESET: {
      return {
        ...INITIAL_STATE,
      };
    }

    case REDUX_HYDRATE: {
      const payload = action?.payload?.clickAndCollect;

      if (payload) return payload;

      return state;
    }

    case '@@redux-form/CHANGE':
    case SILENTLY_SELECT_COLLECTION_DATE: {
      const field = action?.meta?.field;

      if (field === 'collectionDateTime' || action.type === SILENTLY_SELECT_COLLECTION_DATE) {
        if (!state.collectionPointDates || state.collectionPointDates.length === 0) {
          return state;
        }

        const selectedDateIndex = state.collectionPointDates?.findIndex(item => item.date === action.payload);
        const selectedDate = state.collectionPointDates[selectedDateIndex];
        const selectedDateFormatted = format(selectedDate?.date ?? '', 'YYYY-MM-DD');
        const nextDayFormatted = format(addDays(new Date(), 1), 'YYYY-MM-DD');
        const nextDayIsSelected = isEqual(selectedDateFormatted, nextDayFormatted);
        const availableDates = state.collectionPointDates?.filter(({ isAvailable }) => isAvailable);

        let suggestedNewCollectionDate;

        if (state.collectionPointDates?.length === 1) {
          // Collect+ only gives us one date per API call so assume suggested next date is only one in response
          suggestedNewCollectionDate = availableDates[0];
        } else {
          suggestedNewCollectionDate = nextDayIsSelected ? availableDates[1] : {};
        }

        return {
          ...state,
          selectedCollectionDate: selectedDate,
          cutOffTimeForSelectedStore: selectedDate?.cutOffTime ?? null,
          selectedCollectionTime: action.payload,
          focusOnSelectedDayMessage: action.type === '@@redux-form/CHANGE',
          suggestedNewCollectionDate,
        };
      }

      return state;
    }

    case `${GET_SAVED_COLLECTION_POINTS}.LOADING`: {
      return {
        ...state,
        getSavedCollectionPointsApiCallLoading: true,
      };
    }

    case `${GET_SAVED_COLLECTION_POINTS}.SUCCESS`:
    case `${GET_SAVED_COLLECTION_POINTS}.FAILED`: {
      return {
        ...state,
        getSavedCollectionPointsApiCallLoading: false,
      };
    }

    case `${GET_COLLECTION_POINT_DATES}.LOADING`: {
      return {
        ...state,
        selectedCollectionTime: undefined,
        collectionPointDates: undefined,
        getCollectionPointDatesFailed: false,
        getCollectionPointDatesApiCallActive: true,
        collectionDateTimeString: undefined,
        selectingCollectionPointId: action.selectingCollectionPointId,
        collectionPointDatesEmpty: false,
      };
    }

    case `${GET_COLLECTION_POINT_DATES}.FAILED`: {
      return {
        ...state,
        collectionPointDates: undefined,
        selectedCollectionTime: undefined,
        getCollectionPointDatesFailed: true,
        getCollectionPointDatesApiCallActive: false,
        collectionDateTimeString: undefined,
        selectingCollectionPointId: undefined,
        collectionPointDatesEmpty: false,
      };
    }

    case `${GET_COLLECTION_POINT_DATES}.SUCCESS`: {
      const collectionDates = action.result?.collectionDates;

      if (isEmpty(collectionDates)) {
        return {
          ...state,
          collectionPointDatesEmpty: true,
          getCollectionPointDatesApiCallActive: false,
        };
      }

      const { cutOffTime, collectionDateTimeString } = collectionDates?.find(
        (date: { cutOffTime?: string }) => date.hasOwnProperty('cutOffTime'),
      ) ?? {};

      return {
        ...state,
        collectionPointDates: collectionDates,
        cutOffTimeForSelectedStore: cutOffTime,
        getCollectionPointDatesFailed: false,
        collectionDateTimeString,
        selectingCollectionPointId: undefined,
        getCollectionPointDatesApiCallActive: !state.selectingCollectionPointId,
        collectionPointDatesEmpty: false,
      };
    }

    case `${GET_COLLECTION_POINT_DETAILS}.LOADING`:
      return {
        ...state,
        getCollectionPointDetailsCallActive: true,
      };

    case `${GET_COLLECTION_POINT_DETAILS}.FAILED`: {
      return {
        ...state,
        getCollectionPointDetailsCallActive: false,
        collectionPointServiceDown: true,
      };
    }

    case `${GET_ORDER_CONFIRMATION_PAGE}.SUCCESS`: {
      const collectionPoint = action?.result?.order?.deliveries?.[0]?.fulfilment?.collectionInfo;
      if (collectionPoint && !state.selectedCollectionPoint) {
        const restoredCollectionPoint = {
          ...collectionPoint,
          isAvailable: true,
        };

        // TODO reduce the number of places we store the same collection point
        return {
          ...state,
          selectedCollectionPoint: restoredCollectionPoint,
          confirmedCollectionPoint: restoredCollectionPoint,
          activeModalMapMarker: restoredCollectionPoint,
        };
      }

      return state;
    }

    // used when C&C store is selected and partner discount is toggled on/off
    // used when edit basket could have caused a change in collection point charges
    case `${GET_COLLECTION_POINT_DETAILS}.SUCCESS`: {
      const collectionPoint = action?.result?.collectionPoints?.[0];
      const collectionPoints = state.collectionPointSearchResults;

      if (collectionPoints && collectionPoint) {
        const collectionPointIndex = collectionPoints?.findIndex(point => point.id === collectionPoint.id);

        const updatedCollectionPoint = {
          ...collectionPoints[collectionPointIndex],
          ...collectionPoint,
          isDefault: state?.selectedCollectionPoint?.isDefault ?? false,
        };

        const unfilteredCollectionPoints = state?.unfilteredCollectionPoints ?? [];
        const collectionPointExists = unfilteredCollectionPoints?.find(point => point.id === collectionPoint.id);

        if (!collectionPointExists) {
          unfilteredCollectionPoints.push(updatedCollectionPoint);
        }

        return {
          ...state,
          getCollectionPointDetailsCallActive: false,
          unfilteredCollectionPoints,
          selectedCollectionPoint: updatedCollectionPoint,
        };
      }

      return {
        ...state,
        getCollectionPointDetailsCallActive: false,
      };
    }

    case `${FIND_COLLECTION_POINT}.LOADING`:
      return {
        ...state,
        collectionPointSearchActive: !action.refresh,
        storeToPromote: undefined,
      };

    case `${FIND_COLLECTION_POINT}.FAILED`:
      if (
        action?.error?.code === errorCodeConstants.CLIENT_CONNECTIVITY_ERROR ||
        action?.error?.code === errorCodeConstants.INCOMPLETE_COLLECTION_SEARCH
      ) {
        const errorCodeMap: Record<string, string> = {
          [errorCodeConstants.CLIENT_CONNECTIVITY_ERROR]: errorCodeConstants.CLIENT_CONNECTIVITY_ERROR_CLICK_COLLECT,
          [errorCodeConstants.INCOMPLETE_COLLECTION_SEARCH]: errorCodeConstants.INCOMPLETE_COLLECTION_SEARCH,
        };
        const findCollectionPointErrorCode = errorCodeMap[action?.error?.code];

        return {
          ...state,
          collectionPointSearchResults: [],
          collectionPointSearchComplete: false,
          collectionPointSearchActive: false,
          findCollectionPointError: getNonGlobalError(
            {
              error: {
                code: findCollectionPointErrorCode,
              },
            },
            state.findCollectionPointError,
          ),
        };
      }

      return {
        ...state,
        collectionPointSearchResults: [],
        collectionPointSearchComplete: false,
        collectionPointSearchActive: false,
        collectionPointServiceDown: true,
      };

    case `${FIND_COLLECTION_POINT}.SUCCESS`: {
      const collectionPointSearchTerm = action?.collectionPointSearchTerm ?? '';
      const totalCollectionPoints = action?.result?.totalFound ?? 0;
      const cutOffTimeForAllStores = action?.result?.cutOffTime;
      const collectionPointsOwners = action?.result?.collectionPointsOwners;

      const points = action?.result?.collectionPoints ?? [];

      const activeModalMapMarker =
        state.modalStoreId ? points.find((point: { id: string | undefined }) =>
          point.id === state.modalStoreId) : undefined;
      const storeDetailsModalOpen = !!activeModalMapMarker;
      const collectionPointSearchResults = getFilteredCollectionPoints(points);

      const {
        storeToPromoteIndex,
        storeToPromote,
        shouldRemoveFromList,
      } = promotePartnershipStore(collectionPointSearchResults);

      return {
        ...state,
        collectionPointSearchActive: false,
        collectionPointSearchComplete: totalCollectionPoints !== 0,
        selectedCollectionPoint: action.refresh ? state.selectedCollectionPoint : undefined,
        collectionPointSearchTerm,
        unfilteredCollectionPoints: points,
        collectionPointSearchResults,
        collectionPointsOwners,
        activeMapMarker: collectionPointSearchResults[0],
        totalCollectionPoints,
        findCollectionPointError: totalCollectionPoints === 0 ? getNonGlobalError(
          { error: { code: errorCodeConstants.CLIENT_NO_COLLECTION_POINT_FOUND_ERROR } },
          state.findCollectionPointError,
        ) : undefined,
        isFreeCollection: isZeroAmount(collectionPointSearchResults?.[0]?.collectionCharge ?? 0),
        activeModalMapMarker,
        storeDetailsModalOpen,
        cutOffTimeForAllStores,
        earliestCollectionDateTime: action?.result?.earliestCollectionDateTimeString,
        ...storeToPromote && {
          storeToPromote: {
            index: storeToPromoteIndex,
            store: storeToPromote,
            shouldRemoveFromList,
          },
        },
      };
    }

    case `${PUT_CC_DELIVERY_DETAILS}.LOADING`:
      return {
        ...state,
        proceedingToPayment: true,
      };

    case `${PUT_CC_DELIVERY_DETAILS}.FAILED`: {
      const errorCode = action?.error?.code;

      return {
        ...state,
        proceedingToPayment: false,
        collectionPointServiceDown: errorCode !== errorCodeConstants.ORDER_FORM_INCONSISTENT_STATE,
      };
    }

    case `${PUT_CC_DELIVERY_DETAILS}.SUCCESS`: {
      const fulfilmentOfferId = action?.body?.fulfilment?.fulfilmentOfferId;
      const selectedDate = state.collectionPointDates?.find((item) => item.fulfilmentOfferId === fulfilmentOfferId);

      return {
        ...state,
        cutOffTimeForSelectedStore: selectedDate?.cutOffTime ?? null,
        selectedCollectionTime: selectedDate?.date ?? '',
        proceedingToPayment: true,
        confirmedCollectionPoint: action.collectionPoint,
        confirmedCollectorDetails: action.body,
        isInitialSelection: false,
        showCutOffPassedModal: false,
        cutOffPassed: false,
        countdownVisible: false,
        // for re-use in payment page ClickAndCollectCutOffPassedModal scenario
        collectorDetails: action?.body?.collectorDetails,
        giftMessageLines: action?.body?.fulfilment?.giftMessageLines,
        cutOffPassedDuringOrderSubmit: false,
      };
    }

    case `${PUT_CC_DELIVERY_DETAILS_SILENTLY}.FAILED`: {
      return {
        ...state,
        cutOffPassedDuringOrderSubmit: true,
      };
    }

    case `${GET_PAYMENT_PAGE}.FAILED`:
    case `${INIT_PAYMENT_PAGE}.FAILED`:
    case `${GET_PAYMENT_PAGE}.SUCCESS`:
    case `${INIT_PAYMENT_PAGE}.SUCCESS`:
      return {
        ...state,
        proceedingToPayment: false,
      };

    case CLEAR_COLLECTION_POINT_SELECTION:
    case NEW_COLLECTION_POINT_SEARCH:
      return {
        ...state,
        selectedCollectionPoint: undefined,
        showCutOffPassedModal: false,
        suggestedNewCollectionDate: undefined,
        cutOffPassedDuringOrderSubmit: false,
        newCollectionPointSearch: true,
      };

    case SELECT_COLLECTION_POINT: {
      // preserve distanceInMiles when customer searches for points, selects one, then edits basket.
      // distanceInMiles is a property of a search result so it's less jarring if we can persist it
      const shouldPreserveDistanceInMiles = action.collectionPoint.id === state.selectedCollectionPoint?.id &&
        !!state.selectedCollectionPoint?.distanceInMiles;

      const sharedState = {
        selectedCollectionPoint: {
          ...action.collectionPoint,
          ...(shouldPreserveDistanceInMiles && { distanceInMiles: state.selectedCollectionPoint?.distanceInMiles }),
        },
        isInitialSelection: state.isInitialSelection === undefined,
        collectionPointSearchComplete: true,
        getCollectionPointDatesApiCallActive: false,
        savedCollectionView: action.savedCollectionView,
      };
      const ownerId = action?.collectionPoint?.ownerId;

      if (isCollectPlusRelatedType(ownerId)) {
        return {
          ...state,
          ...sharedState,
          selectedCollectionTime: undefined,
          cutOffTimeForSelectedStore: undefined,
          countdownVisible: false,
        };
      }

      return {
        ...state,
        ...sharedState,
      };
    }

    case SET_FIND_COLLECTION_POINT_ERROR: {
      return {
        ...state,
        findCollectionPointError: getNonGlobalError(action, state.findCollectionPointError),
      };
    }

    case SET_COLLECTION_POINT_VIEW_TYPE:
      return {
        ...state,
        activeViewType: action.activeViewType,
      };

    case SET_ACTIVE_MAP_MARKER:
      return {
        ...state,
        activeMapMarker: action.marker,
      };

    case SET_ACTIVE_MODAL_MAP_MARKER:
      return {
        ...state,
        activeModalMapMarker: action.marker,
      };

    case LOGIN_RESET:
      return INITIAL_STATE;

    case ROUTER_LOCATION_CHANGE: {
      const {
        payload: {
          location: {
            pathname = '',
            state: { id: modalStoreId = undefined, condensedMapStoreListView = undefined } = {},
          } = {},
        } = {},
      } = action ?? {};

      const isStoreDetailsRoute = pathname?.includes(STORE_DETAILS) || false;

      if (isStoreDetailsRoute) {
        const modalShowSelectShopButton =
          !state.selectedCollectionPoint || state.selectedCollectionPoint.id !== modalStoreId;

        return {
          ...state,
          activeModalMapMarker: findStoreById(state, modalStoreId),
          storeDetailsModalOpen: true,
          modalShowSelectShopButton,
          hideMapInStoreDetailsModal: condensedMapStoreListView,
        };
      }

      const pathNameParams = pathname.split('/');
      const storeSearchResultsRoute = [VIEW_TYPE_LIST, VIEW_TYPE_MAP].includes(pathNameParams[2]);
      const selectStoreRoute = pathNameParams[2] === STORE_SELECTED;

      let selectedCollectionPoint = state.selectedCollectionPoint;
      let previouslySelectedCollectionPoint = state.previouslySelectedCollectionPoint;
      const shouldReSelectCollectionPoint = selectStoreRoute && !selectedCollectionPoint;

      if (shouldReSelectCollectionPoint) {
        // customer has navigated forward through a store-select route
        selectedCollectionPoint = findStoreById(state, state?.previouslySelectedCollectionPoint?.id);
      } else if (storeSearchResultsRoute) {
        // customer has navigated back to before they selected a collection point
        selectedCollectionPoint = undefined;
        previouslySelectedCollectionPoint = state.selectedCollectionPoint || state.previouslySelectedCollectionPoint;
      }

      return {
        ...state,
        activeViewType: storeSearchResultsRoute ? pathNameParams[2] : state.activeViewType,
        selectedCollectionPoint,
        previouslySelectedCollectionPoint,
        modalStoreId,
        storeDetailsModalOpen: false,
        showCollectionPointDeleteSuccessMessage: false,
        removeCollectionPointOverlayVisible: false,
      };
    }

    case '@@redux-form/UPDATE_SYNC_ERRORS':
      return {
        ...state,
        findCollectionPointError: undefined,
      };

    case REDUX_FORM_SET_SUBMIT_FAILED:
      return {
        ...state,
        findCollectionPointError: undefined,
      };

    case SET_CLICK_COLLECT_COUNTDOWN_VISIBILITY:
      if (action.visible && !state.countdownHasBeenVisible) {
        return {
          ...state,
          countdownVisible: action.visible,
          countdownHasBeenVisible: true,
        };
      }

      return {
        ...state,
        countdownVisible: action.visible,
      };

    case SET_CLICK_COLLECT_CUT_OFF_PASSED_MODAL_VISIBILITY:
      return {
        ...state,
        showCutOffPassedModal: action.visible,
      };

    case SET_CLICK_COLLECT_CUT_OFF_PASSED:
      return {
        ...state,
        cutOffPassed: action.cutOffPassed,
      };

    case SET_SELECTED_DELIVERY_CHOICE_ID:
      if (action.id === deliveryConstants.DELIVERY) {
        return {
          ...state,
          cutOffPassed: false,
        };
      }

      return state;

    case `${DELETE_COLLECTION_POINT}.LOADING`:
      return {
        ...state,
        deleteCollectionPointApiCallActive: true,
        showCollectionPointDeleteFailedMessage: false,
        showCollectionPointDeleteSuccessMessage: false,
      };

    case `${DELETE_COLLECTION_POINT}.FAILED`:
      return {
        ...state,
        deleteCollectionPointApiCallActive: false,
        showCollectionPointDeleteFailedMessage: true,
        collectionPointIdToBeRemoved: undefined,
        removeCollectionPointOverlayVisible: false,
      };

    case `${DELETE_COLLECTION_POINT}.SUCCESS`:
      return {
        ...state,
        deleteCollectionPointApiCallActive: false,
        showCollectionPointDeleteSuccessMessage: true,
        collectionPointIdToBeRemoved: undefined,
        removeCollectionPointOverlayVisible: false,
      };

    case SHOW_REMOVE_COLLECTION_POINT_OVERLAY:
      return {
        ...state,
        collectionPointIdToBeRemoved: action.id,
        removeCollectionPointOverlayVisible: true,
      };

    case HIDE_REMOVE_COLLECTION_POINT_OVERLAY:
      return {
        ...state,
        collectionPointIdToBeRemoved: undefined,
        removeCollectionPointOverlayVisible: false,
      };

    case SET_GEO_LOCATION_SEARCH_ACTIVE_STATUS:
      return {
        ...state,
        geoLocationSearchActive: action.active,
      };

    case `${POST_DELIVERY_PAGE}.SUCCESS`:
    case `${GET_DELIVERY_PAGE}.SUCCESS`:
      return {
        ...state,
        focusOnSelectedDayMessage: false,
      };

    case GOOGLE_MAPS_SCRIPT_LOADED:
      return {
        ...state,
        googleMapsScriptLoading: false,
        googleMapsScriptLoaded: true,
      };

    case GOOGLE_MAPS_SCRIPT_LOADING:
      return {
        ...state,
        googleMapsScriptLoading: true,
        googleMapsScriptLoaded: false,
      };

    default:
      return state;
  }
}
