import { AnyAction } from 'redux';
import findKey from 'lodash/findKey';

// Types
import { PaymentCardProps } from 'types/Payment.types';
import { PaymentState } from 'types/RootState.types';

// Config
import {
  APPLE_PAY_BILLING_ADDRESS_MISSING,
  APPLE_PAY_PAYMENT,
  APPLE_PAY_PAYMENT_CANCEL,
  APPLE_PAY_PAYMENT_FAILURE,
  APPLE_PAY_REQUEST_PAYMENT_SESSION,
  APPLE_PAY_SELECTED,
  APPLE_PAY_SUBMIT_PAYMENT_DATA,
  APPLY_GIFT_CARD,
  APPLY_GIFT_VOUCHER,
  APPLY_PROMO_CODE,
  APPS_AUTHENTICATED_HANDOVER,
  APPS_GUEST_HANDOVER,
  PAYPAL_STUBS_MODAL_CLOSE,
  PAYPAL_STUBS_MODAL_OPEN,
  CARD_FORM_VALIDATION_STATUS,
  CARD_PAYMENT,
  CARD_TYPE_MISSING,
  CHANGE_PAYMENT_DETAILS,
  CLEARPAY_SCRIPT_LOADING,
  CLEARPAY_SCRIPT_LOADED,
  CLEARPAY_SCRIPT_FAILED,
  CLEARPAY_MESSAGE_SCRIPT_LOADED,
  CLEARPAY_STUBS_MODAL_OPEN,
  CLEARPAY_STUBS_MODAL_CLOSE,
  CLEARPAY_SAVE_CARD,
  CLEARPAY_ERROR,
  CLEARPAY_CANCEL,
  CLEARPAY_ENABLED_STATUS,
  CLOSE_POS_CREDIT_GET_READY_MODAL,
  CREATE_GOOGLE_PAY_PAYMENT,
  CREATE_PAYPAL_PAYMENT,
  DETERMINE_SELECTED_PAYMENT_TYPE,
  GET_DELIVERY_PAGE,
  GET_ORDER_CONFIRMATION_PAGE,
  GET_PAYMENT_PAGE,
  GET_PAYMENT_WALLET,
  GET_PAYPAL_CLIENT_TOKEN,
  GIFT_OPTIONS_CHANGE_OPTION,
  GIFT_OPTIONS_MODAL_CLOSE,
  GOOGLE_PAY_CANCEL,
  GOOGLE_PAY_ENABLED,
  GOOGLE_PAY_DEFAULT,
  GOOGLE_PAY_PAYMENT_DATA_ERROR,
  GOOGLE_PAY_PAYMENT_DATA_RECEIVED,
  GOOGLE_PAY_STUBS_MODAL_OPEN,
  GOOGLE_PAY_SUBMIT_PAYMENT_DATA,
  GOOGLE_PAY_WALLET_ELIGIBLE,
  GOOGLE_SCRIPT_FAILED,
  GOOGLE_SCRIPT_LOADED,
  GOOGLE_SCRIPT_LOADING,
  HAND_OVER,
  INIT_PAYMENT_PAGE,
  IOVATION_SCRIPTS_FAILED,
  IOVATION_SCRIPTS_LOADED,
  IOVATION_SCRIPTS_LOADING,
  KLARNA_CANCEL,
  KLARNA_ERROR,
  KLARNA_INITIALISE_ERROR,
  KLARNA_LOAD_COMPLETE,
  KLARNA_PAYMENT_STARTED,
  KLARNA_SAVE_CARD,
  KLARNA_SCRIPT_LOADING,
  KLARNA_SCRIPT_LOADED,
  KLARNA_SCRIPT_FAILED,
  KLARNA_SDK_SCRIPT_LOADING,
  KLARNA_SDK_SCRIPT_LOADED,
  KLARNA_SDK_SCRIPT_FAILED,
  KLARNA_STUBS_MODAL_OPEN,
  KLARNA_STUBS_MODAL_CLOSE,
  LOGIN_RESET,
  OCP_SUBMIT_3DS_METHOD,
  OCP_SUBMIT_3DS_METHOD_ERROR,
  PAYMENT_CARD_DELETE,
  PAYPAL_PAYMENT_CANCEL,
  PAYPAL_PAYMENT_ENDED,
  PAYPAL_PAYMENT_FAILED,
  PAYPAL_PAYMENT_STARTED,
  POS_CREDIT_PAYMENT,
  POST_DELIVERY_PAGE,
  POST_DELIVERY_PAGE_NO_ACTION,
  REDUX_FORM_SET_SUBMIT_FAILED,
  REDUX_HYDRATE,
  REMOVE_PROMO_CODE,
  REMOVE_GIFT_OPTION,
  RESET_SUBMIT_ORDER_ERROR,
  ROUTER_LOCATION_CHANGE,
  SAVED_CARD_SELECTED,
  SET_APPLE_PAY_PAYMENT_REQUEST,
  SET_CAN_MAKE_APPLE_PAY_PAYMENTS,
  SET_CAN_MAKE_GOOGLE_PAY_PAYMENTS,
  SET_CARD_PAYMENT_3DSECURE_CANCELLED_STATUS,
  SET_CARD_PAYMENT_PREPAID_CANCELLED_STATUS,
  SET_CHECK_ELIGIBILITY_PROCESSING,
  SET_OCP_3DS_FAIL_ERROR,
  SET_PARTNER_DISCOUNT,
  SET_PAYMENT_PROCESSING,
  SET_PAYMENT_TYPE,
  SET_PAYPAL_SERVICE_UNAVAILABLE,
  SET_PROMO_CODE_ERROR,
  SET_SELECTED_SAVED_CARD,
  SHOW_POS_CREDIT_GET_READY_MODAL,
  SUBMIT_ORDER,
  UPDATE_BILLING_ADDRESS,
  GET_PAYMENTS,
  EXPRESS_PAYMENTS_FAILED,
  OCP_GET_SUBMISSION_SCRIPT,
  OCP_GET_PAYMENT_ID,
  INIT_EXPRESS_PAYMENT,
  REBATCH_ORDER,
  GET_GIFTING_PAYMENT_DETAILS,
  SHOW_EXPRESS_PAYMENTS,
  SET_CAN_MAKE_PAYPAL_PAY_EXPRESS_PAYMENTS,
  UPDATE_SHIPPING_ADDRESS,
  KLARNA_PAYMENT_VISIBLE,
  KLARNA_ENABLED_STATUS,
  SET_PAYPAL_PAYLATER_IS_NOT_ELIGIBLE,
  SET_POS_CREDIT_APPLICATION_STATUS,
} from '../../../constants/actionConstants';
import analyticsConstants from '../../../constants/analyticsConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import paymentStatusConstants from '../../../constants/paymentStatusConstants';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';
import routeConstants from '../../../constants/routeConstants';
import supportedCreditCardTypes from '../../../constants/supportedCreditCardTypes';
import { getNonGlobalError } from '../../../utils/error/parseError';
import submitOrderCanRetry from '../../../utils/orderform/submitOrderCanRetry';

export const INITIAL_STATE = {
  alreadyLoaded: false,
  applePayCompatibilityChecksComplete: false,
  applePayPaymentRequest: undefined,
  applePayPaymentSessionApiCallActive: false,
  applePayResponse: undefined,
  billingAddress: undefined,
  payPalStubsModalOpen: undefined,
  cancelPaypalRedirect: false,
  canMakeGooglePayPayments: false,
  isCardFormValid: true,
  creditCard3DSecureInfo: {},
  creditTypeGroups: [],
  getPaymentPageCallActive: false,
  giftOptionsModalContentId: '',
  giftOptionsModalOpen: undefined,
  googlePayBaseCardPaymentMethod: {},
  googlePayCardPaymentMethod: {},
  googlePayPaymentData: undefined,
  googlePayPaymentsClientHasBeenCreated: false,
  googlePayStubsModalOpen: undefined,
  googlePayWalletEligible: false,
  initPaymentPageCallActive: false,
  invalidGooglePayBillingAddress: undefined,
  ocpPaymentId: '',
  partnerDiscountInvalid: false,
  paymentCardDeleteApiCallActive: false,
  paymentCardDeleteApiCallFailed: false,
  paymentCardDeleted: undefined,
  paymentFormsValidationError: undefined,
  paymentNotSelectedError: undefined,
  paymentNotSelectedErrorMinor: undefined,
  paymentProcessing: false,
  paymentWallet: undefined,
  payOptionsAnalytics: {},
  payPalAuthorisationUnavailable: false,
  payPalPayExpressCompatibilityChecksComplete: false,
  payPalPaymentBillingAddressRequest: undefined,
  payPalPaymentShippingAddressRequest: undefined,
  payPalServiceUnavailable: undefined,
  paypalUnavailableError: undefined,
  promoCodeError: undefined,
  promoCodeSubmissionError: undefined,
  posCreditEligible: false,
  posCreditApplicationStatus: null,
  savedPaymentFormsValidationError: undefined,
  selectedPaymentType: null,
  shouldShowPaymentCardDeleteModal: false,
  show3dSecureFrame: false,
  show3dsMethodFrame: false,
  showAddressBookModal: false,
  showMarketingPreferencesPrompt: undefined,
  submitOrderApiCallActive: false,
  submitOrderError: undefined,
  submitOrderLoading: false,
  threeDSMethodInfo: {},
  selectedSavedCard: undefined,
  isExpressPayment: false,
};

export function setSelectedSavedCard(selectedPaymentType: string | undefined) {
  if (!selectedPaymentType) return undefined;

  return selectedPaymentType?.split(':')[1];
}

export default function paymentReducer(state: PaymentState = INITIAL_STATE, action: AnyAction) {
  const actionType = action?.type || '';

  switch (actionType) {
    case REDUX_HYDRATE: {
      const payload = action?.payload?.payment;

      return {
        ...payload,
        submitOrderApiCallActive: false,
      };
    }

    case `${APPS_GUEST_HANDOVER}.SUCCESS`:
    case `${APPS_AUTHENTICATED_HANDOVER}.SUCCESS`:
    case `${HAND_OVER}.SUCCESS`: {
      return {
        ...state,
        selectedPaymentType: '',
      };
    }
    case LOGIN_RESET:
      return {
        ...INITIAL_STATE,
        selectedPaymentType: null,
      };

    case `${SET_CHECK_ELIGIBILITY_PROCESSING}.TRUE`:
      return {
        ...state,
        checkPOSCreditEligibilityProcessing: true,
      };

    case `${SET_CHECK_ELIGIBILITY_PROCESSING}.FALSE`:
      return {
        ...state,
        checkPOSCreditEligibilityProcessing: false,
      };

    case `${SET_PAYMENT_PROCESSING}.TRUE`:
      return {
        ...state,
        paymentProcessing: true,
        submitOrderError: undefined,
      };

    case `${SET_PAYMENT_PROCESSING}.FALSE`:
      return {
        ...state,
        paymentProcessing: false,
      };

    case `${SUBMIT_ORDER}.LOADING`:
      return {
        ...state,
        paymentProcessing: true,
        submitOrderApiCallActive: true,
      };

    case CARD_FORM_VALIDATION_STATUS:
      return {
        ...state,
        isCardFormValid: action?.isCardFormValid,
      };

    case OCP_SUBMIT_3DS_METHOD_ERROR:
      return {
        ...state,
        show3dsMethodFrame: false,
        submitOrderError: getNonGlobalError(action),
      };

    case `${OCP_SUBMIT_3DS_METHOD}.LOADING`:
      return {
        ...state,
        show3dsMethodFrame: false,
      };

    case `${OCP_SUBMIT_3DS_METHOD}.SUCCESS`: {
      const status = action.result?.status;
      const challengeCheck = action.result?.challengeCheck ?? {};

      if (status === paymentStatusConstants.PENDING_CHALLENGE) {
        const { actionUrl, formData } = challengeCheck;

        return {
          ...state,
          creditCard3DSecureInfo: {
            is3DSecureAvailable: true,
            creditCard3DSecureFormId: 'paymentForm',
            creditCard3DSecureMerchantUrl: actionUrl,
            creditCard3DSecureFormData: formData,
          },
          submitOrderError: undefined,
          show3dSecureFrame: true,
        };
      }

      return state;
    }

    case `${SUBMIT_ORDER}.SUCCESS`:
      return {
        ...state,
        paymentProcessing: false,
        submitOrderApiCallActive: false,
      };

    case `${UPDATE_BILLING_ADDRESS}.LOADING`: {
      return {
        ...state,
        billingAddress: undefined,
        paymentProcessing: true,
      };
    }

    case `${UPDATE_BILLING_ADDRESS}.SUCCESS`: {
      if (state.paypalNonce || state.invalidPayPalBillingAddress) {
        return {
          ...state,
          billingAddress: action.billingAddress,
          paypalNonce: undefined,
          invalidPayPalBillingAddress: undefined,
        };
      }

      if (state.invalidGooglePayBillingAddress) {
        return {
          ...state,
          billingAddress: action.billingAddress,
          invalidGooglePayBillingAddress: undefined,
        };
      }

      return {
        ...state,
        billingAddress: action.billingAddress,
      };
    }

    case `${UPDATE_SHIPPING_ADDRESS}.FAILED`:
      return {
        ...state,
        invalidPayPalShippingAddress: action.shippingAddressFromPaypal,
        paypalNonce: action.paypalNonce,
        shippingOptionId: action.shippingOptionId,
      };

    // TODO reduce complexity here as there's lots of different scenarios happening in one block
    case APPLE_PAY_BILLING_ADDRESS_MISSING:
    case `${UPDATE_BILLING_ADDRESS}.FAILED`:
    case `${SUBMIT_ORDER}.FAILED`:
    case `${CARD_PAYMENT}.FAILED`:
    case `${GET_ORDER_CONFIRMATION_PAGE}.FAILED`: {
      if (submitOrderCanRetry(action)) {
        return state;
      }

      let selectedPaymentType = state.selectedPaymentType;
      let payPalAuthorisationUnavailable = state.payPalAuthorisationUnavailable;

      if (action.error?.code === errorCodeConstants.PARTNER_CARD_NUMBER_INVALID) {
        return {
          ...state,
          paymentProcessing: false,
          show3dSecureFrame: false,
          selectedPaymentType,
          submitOrderApiCallActive: false,
          partnerDiscountInvalid: true,
          promoCodeError: null,
        };
      }

      if (action.error?.code === errorCodeConstants.PAYMENT_POS_CREDIT_DECLINED_ERROR ||
          action.error?.code === errorCodeConstants.PAYMENT_POS_CREDIT_CANCELLED_ERROR) {
        selectedPaymentType = undefined;
      }

      if (action.error?.code === errorCodeConstants.PAYPAL_AUTHORISATION_DECLINED ||
          action.error?.code === errorCodeConstants.PAYPAL_AUTHORISATION_DECLINED_WITH_RETRY_PERMITTED ||
          action.error?.code === errorCodeConstants.PAYMENT_SERVICE_PAYPAL_AUTHORISATION_FAILED) {
        selectedPaymentType = undefined;
      }

      if (action.error?.code === errorCodeConstants.PAYMENT_SERVICE_PAYPAL_AUTHORISATION_UNAVAILABLE ||
          action.error?.code === errorCodeConstants.PAYMENT_SERVICE_PAYPAL_FRAUD_DATA_UNAVAILABLE ||
          action.error?.code === errorCodeConstants.PAYMENT_SERVICE_PAYPAL_FRAUD_DATA_FAILED) {
        payPalAuthorisationUnavailable = true;
        selectedPaymentType = undefined;
      }

      const isPaypalBillingAddressInvalid = action.type === `${UPDATE_BILLING_ADDRESS}.FAILED` &&
        (selectedPaymentType?.includes(paymentTypeConstants.PAYPAL) || false);

      const isGooglePayBillingAddressInvalid = action.type === `${UPDATE_BILLING_ADDRESS}.FAILED` &&
        selectedPaymentType === paymentTypeConstants.GOOGLE_PAY;

      const isPromoCodeSubmissionError = action?.error?.code?.includes('promo');

      const submitOrderError = (
        isPaypalBillingAddressInvalid ||
        isGooglePayBillingAddressInvalid ||
        isPromoCodeSubmissionError
      ) ?
        null : getNonGlobalError(action, state.submitOrderError, {
          isPosCreditSubmissionError: action.selectedPaymentType?.includes(paymentTypeConstants.POS_CREDIT) || false,
        });

      if ([
        errorCodeConstants.PAYMENT_POS_CREDIT_DECLINED_ERROR,
        errorCodeConstants.PAYMENT_POS_CREDIT_CANCELLED_ERROR,
        errorCodeConstants.PAYMENT_POS_CREDIT_APPLICATION_INCOMPLETE,
      ].includes(action.error?.code)) {
        submitOrderError.isPosCreditError = true;
      }

      return {
        ...state,
        paymentProcessing: false,
        submitOrderError,
        show3dSecureFrame: false,
        selectedPaymentType,
        submitOrderApiCallActive: false,
        payPalAuthorisationUnavailable,
        promoCodeError: null,
        ...isPaypalBillingAddressInvalid && {
          invalidPayPalBillingAddress: action.billingAddressFromPaypal,
          paypalNonce: action.paypalNonce,
        },
        ...isGooglePayBillingAddressInvalid && {
          invalidGooglePayBillingAddress: action.googlePayBillingAddress,
        },
        showExpressPaymentsFailedMessage: true,
      };
    }

    case `${REBATCH_ORDER}.SUCCESS`: {
      return {
        ...state,
        showExpressPaymentsFailedMessage: false,
      };
    }

    case `${CARD_PAYMENT}.SUCCESS`: {
      const threeDSMethodInfo = action.result?.threeDSMethodInfo ?? {};
      const ocpPaymentId = action.result?.ocpPaymentId;
      const isThreeDSMethodRequired = threeDSMethodInfo.threeDSMethodRequired;
      if (isThreeDSMethodRequired) {
        return {
          ...state,
          ocpPaymentId,
          threeDSMethodInfo,
          submitOrderError: undefined,
          show3dsMethodFrame: isThreeDSMethodRequired,
        };
      }

      const creditCard3DSecureInfo = action.result?.creditCard3DSecureInfo ?? {};
      const is3DSecureAvailable = creditCard3DSecureInfo.is3DSecureAvailable;
      const transformedCreditCard3DSecureInfo = is3DSecureAvailable ? {
        creditCard3DSecureFormId: 'paymentForm',
        ...creditCard3DSecureInfo,
      } : creditCard3DSecureInfo;

      return {
        ...state,
        creditCard3DSecureInfo: transformedCreditCard3DSecureInfo,
        submitOrderError: undefined,
        show3dSecureFrame: is3DSecureAvailable,
      };
    }

    case SET_CARD_PAYMENT_3DSECURE_CANCELLED_STATUS: {
      const error = getNonGlobalError(
        { error: { code: errorCodeConstants.CLIENT_PAYMENT_3DS_CANCELLED_ERROR } },
        state.submitOrderError,
      );

      return {
        ...state,
        show3dSecureFrame: false,
        submitOrderError: error,
        showPartnerCardError: action?.cardType === supportedCreditCardTypes.NEW_DAY,
        paymentProcessing: false,
      };
    }

    case SET_CARD_PAYMENT_PREPAID_CANCELLED_STATUS: {
      const error = getNonGlobalError(
        { error: { code: errorCodeConstants.CLIENT_PREPAID_CARD_PAYMENT_ERROR } },
        state.submitOrderError,
      );

      return {
        ...state,
        show3dSecureFrame: false,
        submitOrderError: error,
        paymentProcessing: false,
      };
    }

    case RESET_SUBMIT_ORDER_ERROR:
      return {
        ...state,
        submitOrderError: undefined,
      };

    case SAVED_CARD_SELECTED: {
      return {
        ...state,
        payOptionsAnalytics: {
          ...state.payOptionsAnalytics,
          [paymentTypeConstants.SAVED_PAYMENT_CARD]: {
            default: 1,
            enabled: 1,
          },
        },
      };
    }

    case APPLE_PAY_SELECTED: {
      return {
        ...state,
        payOptionsAnalytics: {
          ...state.payOptionsAnalytics,
          [paymentTypeConstants.APPLE_PAY]: {
            default: 1,
            enabled: 1,
          },
        },
      };
    }

    case GOOGLE_PAY_ENABLED: {
      return {
        ...state,
        payOptionsAnalytics: {
          ...state.payOptionsAnalytics,
          [paymentTypeConstants.GOOGLE_PAY]: {
            default: 0,
            enabled: 1,
          },
        },
      };
    }

    case GOOGLE_PAY_DEFAULT: {
      return {
        ...state,
        payOptionsAnalytics: {
          ...state.payOptionsAnalytics,
          [paymentTypeConstants.GOOGLE_PAY]: {
            default: 1,
            enabled: 1,
          },
        },
      };
    }

    case SET_PAYMENT_TYPE: {
      const { paymentType, posCreditType } = action;

      const paymentTypesAnalyticsMappings: Record<string, string> = {
        [paymentTypeConstants.APPLE_PAY]: 'applePay',
        [paymentTypeConstants.APPLE_PAY_EXPRESS]: 'applePayExpress',
        [paymentTypeConstants.PAYPAL]: 'payPal',
        [paymentTypeConstants.PAYPAL_PAY_LATER]: 'payPal',
        [paymentTypeConstants.PAYPAL_EXPRESS]: 'paypalExpress',
        [paymentTypeConstants.CREDIT_CARD]: analyticsConstants.CC_NEW,
        [paymentTypeConstants.SAVED_PAYMENT_CARD]: analyticsConstants.CC_SAVED,
        [paymentTypeConstants.GOOGLE_PAY]: 'googlePay',
        [paymentTypeConstants.CLEARPAY]: 'clearpay',
        [paymentTypeConstants.PREPAID_CARD]: 'prepaidCard',
        [paymentTypeConstants.KLARNA]: 'klarna',
      };

      const parsedPaymentType = paymentType.split(':');
      const isDeferred = parsedPaymentType.includes('deferred');
      const newPaymentTypeParsed = parsedPaymentType[0] === paymentTypeConstants.POS_CREDIT ?
        `posc_${isDeferred ? 'deferred_' : ''}${posCreditType}` :
        paymentTypesAnalyticsMappings[parsedPaymentType[0]];

      const oldPaymentType = findKey(state.payOptionsAnalytics, { selected: 1 });

      const newPayOptionsAnalytics = {
        ...state.payOptionsAnalytics,
        ...(!!oldPaymentType && { [oldPaymentType]: {
          ...state.payOptionsAnalytics?.[oldPaymentType],
          selected: 0, // payment option not selected, but has been selected previously
        } }),
        ...(!!newPaymentTypeParsed && { [newPaymentTypeParsed]: {
          ...state.payOptionsAnalytics?.[newPaymentTypeParsed],
          selected: 1, // payment option currently selected
        } }),
      };

      return {
        ...state,
        selectedPaymentType: paymentType,
        submitOrderError: undefined,
        paymentNotSelectedError: undefined,
        paymentNotSelectedErrorMinor: undefined,
        payOptionsAnalytics: newPayOptionsAnalytics,
        promoCodeSubmissionError: null,
        showPopulatedNewCardRadio: false,
        ...(paymentType === paymentTypeConstants.KLARNA ? {
          klarnaScriptFailed: false,
          klarnaSdkScriptFailed: false,
        } : {}),
      };
    }

    case SET_POS_CREDIT_APPLICATION_STATUS: {
      return {
        ...state,
        posCreditApplicationStatus: action.status,
      };
    }

    case `${GET_PAYMENT_PAGE}.LOADING`: {
      return {
        ...state,
        getPaymentPageCallActive: true,
        paymentCardDeleted: undefined,
        paymentCardDeleteApiCallFailed: false,
      };
    }

    case `${GET_PAYMENT_PAGE}.FAILED`: {
      return {
        ...state,
        getPaymentPageCallActive: false,
      };
    }

    case `${INIT_PAYMENT_PAGE}.LOADING`: {
      return {
        ...state,
        initPaymentPageCallActive: true,
        paymentCardDeleted: undefined,
        paymentCardDeleteApiCallFailed: false,
      };
    }

    case `${INIT_PAYMENT_PAGE}.FAILED`: {
      return {
        ...state,
        initPaymentPageCallActive: false,
      };
    }

    case `${APPLY_PROMO_CODE}.FAILED`: {
      return {
        ...state,
        promoCodeSubmissionError: undefined,
      };
    }

    case `${APPLY_PROMO_CODE}.SUCCESS`:
    case `${REMOVE_PROMO_CODE}.SUCCESS`: {
      const posCreditEligible = action.result?.creditPaymentDetails?.displayOption ?? false;

      return {
        ...state,
        promoCodeError: undefined,
        posCreditEligible,
        creditTypeGroups: action.result?.creditPaymentDetails?.creditTypeGroups ?? [],
        payPalPaymentRequest: action.result?.payPalPaymentRequest,
        // Basket value alterations mean POS_CREDIT options change, so unset selectedPaymentType
        // here to allow determineSelectedPaymentType to choose new option
        selectedPaymentType: state.selectedPaymentType?.includes(paymentTypeConstants.POS_CREDIT) ?
          undefined :
          state.selectedPaymentType,
        promoCodeSubmissionError: state.promoCodeSubmissionError && action.type === `${APPLY_PROMO_CODE}.SUCCESS` ?
          undefined :
          state.promoCodeSubmissionError,
      };
    }

    case DETERMINE_SELECTED_PAYMENT_TYPE:
      return {
        ...state,
        selectedPaymentType: action.selectedPaymentType,
        selectedSavedCard: setSelectedSavedCard(action.selectedPaymentType),
      };

    case `${GET_PAYMENTS}.SUCCESS`: {
      const posCreditEligible = action.result?.creditPaymentDetails?.displayOption ?? false;
      const selectedPaymentType = state.selectedPaymentType?.includes(paymentTypeConstants.POS_CREDIT) ?
        undefined :
        state.selectedPaymentType;

      return {
        ...state,
        amounts: action.result.amounts,
        payments: action.result.payments,
        payPalPaymentRequest: action.result.payPalPaymentRequest,
        selectedPaymentType,
        posCreditEligible,
        creditTypeGroups: action.result?.creditPaymentDetails?.creditTypeGroups ?? [],
      };
    }

    case `${GET_PAYMENT_PAGE}.SUCCESS`:
    case `${INIT_PAYMENT_PAGE}.SUCCESS`: {
      const payOptionsAnalytics = action.result?.analytics?.checkout?.payment?.payOptions ?? state.payOptionsAnalytics;
      const posCreditEligible = action.result?.creditPaymentDetails?.displayOption ?? false;
      const giftingPaymentDetails = action.result?.giftingPaymentDetails;

      return {
        ...state,
        cancelPaypalRedirect: false,
        paymentProcessing: false,
        initPaymentPageCallActive: false,
        getPaymentPageCallActive: false,
        showMarketingPreferencesPrompt: action.result?.marketingPreferencesPromptVisible,
        posCreditEligible,
        payOptionsAnalytics,
        creditTypeGroups: action.result?.creditPaymentDetails?.creditTypeGroups ?? [],
        ...giftingPaymentDetails && { giftingPaymentDetails },
        alreadyLoaded: !!action.pushAfter,
        payPalPaymentRequest: action.result?.payPalPaymentRequest,
        isExpressPayment: false,
      };
    }

    case `${GET_GIFTING_PAYMENT_DETAILS}.LOADING`: {
      return {
        ...state,
        getGiftingPaymentDetailsFailed: false,
      };
    }

    case `${GET_GIFTING_PAYMENT_DETAILS}.FAILED`: {
      return {
        ...state,
        getGiftingPaymentDetailsFailed: true,
      };
    }

    case `${GET_GIFTING_PAYMENT_DETAILS}.SUCCESS`: {
      return {
        ...state,
        giftingPaymentDetails: action?.result,
        getGiftingPaymentDetailsFailed: false,
      };
    }

    case `${PAYMENT_CARD_DELETE}.LOADING`: {
      return {
        ...state,
        paymentCardDeleteApiCallActive: true,
        paymentCardDeleteApiCallFailed: false,
        paymentCardDeleted: undefined,
      };
    }

    case `${PAYMENT_CARD_DELETE}.FAILED`: {
      if (action.error?.code === errorCodeConstants.CLIENT_PAYMENT_CARD_NOT_DELETED) {
        const last4Digits = action.last4Digits;

        return {
          ...state,
          paymentCardDeleteApiCallActive: false,
          paymentCardDeleteApiCallFailed: false,
          paymentCardDeleted: last4Digits,
        };
      }

      return {
        ...state,
        paymentCardDeleteApiCallActive: false,
        paymentCardDeleteApiCallFailed: true,
        paymentCardDeleted: undefined,
      };
    }

    case `${PAYMENT_CARD_DELETE}.SUCCESS`: {
      const last4Digits = action.last4Digits;
      const paymentWallet: PaymentCardProps[] = action.result?.paymentWallet ?? [];
      const selectedSavedCardStillExists = !!paymentWallet.find((item) => item.id === state.selectedSavedCard);

      return {
        ...state,
        paymentCardDeleteApiCallActive: false,
        paymentCardDeleteApiCallFailed: false,
        paymentCardDeleted: last4Digits,
        paymentWallet,
        selectedPaymentType: selectedSavedCardStillExists ? state.selectedPaymentType : undefined,
      };
    }

    case `${GET_PAYMENT_WALLET}.SUCCESS`: {
      return {
        ...state,
        paymentWallet: action.result?.paymentWallet ?? [],
      };
    }

    case SET_OCP_3DS_FAIL_ERROR:
      return {
        ...state,
        submitOrderError: getNonGlobalError(
          { error: { code: errorCodeConstants.CLIENT_PAYMENT_OCP_3DS_INVALID_ERROR } },
          state.submitOrderError,
        ),
        show3dSecureFrame: false,
      };

    case GIFT_OPTIONS_CHANGE_OPTION:
      return {
        ...state,
        giftOptionsModalContentId: action.contentId,
      };

    case `${APPLY_GIFT_CARD}.LOADING`:
    case `${APPLY_GIFT_VOUCHER}.LOADING`:
      return {
        ...state,
        applyGiftPaymentFailed: false,
      };

    case `${APPLY_GIFT_CARD}.SUCCESS`:
    case `${APPLY_GIFT_VOUCHER}.SUCCESS`:
      return {
        ...state,
        applyGiftPaymentFailed: false,
        payPalPaymentRequest: action.result?.payPalPaymentRequest,
      };

    case `${APPLY_GIFT_CARD}.FAILED`:
    case `${APPLY_GIFT_VOUCHER}.FAILED`:
      return {
        ...state,
        applyGiftPaymentFailed: true,
      };

    case `${REMOVE_GIFT_OPTION}.SUCCESS`:
      return {
        ...state,
        payPalPaymentRequest: action.result?.payPalPaymentRequest,
      };

    case CHANGE_PAYMENT_DETAILS:
      return {
        ...state,
        selectedPaymentType: null,
      };

    case ROUTER_LOCATION_CHANGE: {
      const pathname = action.payload?.location?.pathname;
      const searchParams = new URLSearchParams(action.payload?.location?.search);
      const query = Object.fromEntries(searchParams);

      if (query.processing === '') {
        return {
          ...state,
          show3dSecureFrame: false,
        };
      }

      // if we are not on the payment page/s...
      if (!pathname.includes(routeConstants.PAYMENT)) {
        return {
          ...state,
          submitOrderError: undefined,
          cancelPaypalRedirect: state.paymentProcessing,
          paymentNotSelectedError: undefined,
          paymentNotSelectedErrorMinor: undefined,
          paymentCardDeleteApiCallFailed: false,
          payPalServiceUnavailable: undefined,
          invalidPayPalBillingAddress: undefined,
          invalidPayPalShippingAddress: undefined,
          paypalNonce: undefined,
          checkPOSCreditEligibilityProcessing: false,
          showPOSGetReadyModal: false,
        };
      }

      switch (pathname) {
        case routeConstants.PAYMENT_CARD_DELETE: {
          return {
            ...state,
            shouldShowPaymentCardDeleteModal: true,
          };
        }
        case routeConstants.PAYMENT_ADDRESS_BOOK: {
          return {
            ...state,
            showAddressBookModal: true,
          };
        }
        case routeConstants.PAYMENT_ADDRESS_BOOK_NEW:
          return {
            ...state,
            showAddressBookModal: true,
          };
        case routeConstants.PAYMENT_ADDRESS_BOOK_EDIT:
          return {
            ...state,
            showAddressBookModal: true,
          };
        case routeConstants.PAYMENT_GIFT_OPTIONS:
          return {
            ...state,
            giftOptionsModalOpen: true,
            giftOptionsModalContentId: '',
          };
        default:
          return {
            ...state,
            showAddressBookModal: false,
            shouldShowPaymentCardDeleteModal: false,
            giftOptionsModalOpen: false,
            paymentProcessing: state.selectedPaymentType === paymentTypeConstants.PAYPAL
              ? false : state.paymentProcessing,
          };
      }
    }

    case REDUX_FORM_SET_SUBMIT_FAILED:
      return {
        ...state,
        paymentFormsValidationError: undefined,
        savedPaymentFormsValidationError: undefined,
      };

    case SET_PROMO_CODE_ERROR: {
      if (action.body) {
        return {
          ...state,
          promoCodeError: {
            title: action.message,
            body: action.body,
          },
        };
      }

      if (action.orderSubmissionFailure) {
        return {
          ...state,
          promoCodeSubmissionError: action.message,
        };
      }

      return {
        ...state,
        promoCodeError: action.message,
      };
    }

    case PAYPAL_PAYMENT_FAILED:
      return {
        ...state,
        ...!action?.isPaypalExpress && {
          submitOrderError: {
            body: 'Please try again or try using another payment method',
            code: errorCodeConstants.UI_SCROLL_TO_ERROR,
            message: 'Sorry, your PayPal payment was unsuccessful',
          },
        },
        selectedPaymentType: undefined,
        showExpressPaymentsFailedMessage: state.selectedPaymentType === paymentTypeConstants.PAYPAL_EXPRESS,
        payPalPaymentProcessing: false,
        invalidPayPalBillingAddress: undefined,
        invalidPayPalShippingAddress: undefined,
        payPalPaymentBillingAddressRequest: undefined,
        payPalPaymentShippingAddressRequest: undefined,
        shippingOptionId: undefined,
      };

    case `${POST_DELIVERY_PAGE_NO_ACTION}.LOADING`:
    case `${GET_DELIVERY_PAGE}.LOADING`:
    case `${POST_DELIVERY_PAGE}.LOADING`:
      return {
        ...state,
        alreadyLoaded: false,
        cancelPaypalRedirect: true,
        paymentProcessing: false,
      };

    case `${SET_PARTNER_DISCOUNT}.SUCCESS`: {
      return {
        ...state,
        partnerDiscountEnabled: action.partnerDiscountEnabled,
      };
    }

    case SET_CAN_MAKE_APPLE_PAY_PAYMENTS:
      return {
        ...state,
        applePayCompatibilityChecksComplete: true,
      };

    case SET_APPLE_PAY_PAYMENT_REQUEST:
      return {
        ...state,
        applePayPaymentRequest: action.paymentRequest,
      };

    case `${APPLE_PAY_PAYMENT}.SUCCESS`:
      return {
        ...state,
        ocpPaymentId: action.result?.id,
        applePayPaymentRequest: action.result?.applePayPaymentRequest,
      };

    case APPLE_PAY_PAYMENT_CANCEL:
      return {
        ...state,
        paymentProcessing: false,
        submitOrderError: !action?.express ? {
          message: 'Your payment was cancelled',
          body: 'Please try again or try using another payment method',
        } : undefined,
        selectedPaymentType: action?.express ? undefined : state.selectedPaymentType,
      };

    case APPLE_PAY_PAYMENT_FAILURE:
      return {
        ...state,
        paymentProcessing: false,
        submitOrderError: !action.express ? {
          message: 'Sorry, we are unable to process this payment',
          body: 'Please try again or use another payment method',
        } : undefined,
        selectedPaymentType: action?.express ? undefined : state.selectedPaymentType,
      };

    case `${CREATE_GOOGLE_PAY_PAYMENT}.FAILED`:
    case `${GOOGLE_PAY_SUBMIT_PAYMENT_DATA}.FAILED`:
    case GOOGLE_PAY_PAYMENT_DATA_ERROR:
      return {
        ...state,
        paymentProcessing: false,
        submitOrderError: {
          message: 'Sorry, we are unable to process this payment',
          body: 'Please try again or use another payment method',
        },
      };

    case `${APPLE_PAY_REQUEST_PAYMENT_SESSION}.LOADING`:
      return {
        ...state,
        applePayPaymentSessionApiCallActive: true,
      };

    case `${APPLE_PAY_REQUEST_PAYMENT_SESSION}.FAILED`:
    case `${APPLE_PAY_REQUEST_PAYMENT_SESSION}.SUCCESS`:
      return {
        ...state,
        applePayPaymentSessionApiCallActive: false,
      };

    case SET_CAN_MAKE_GOOGLE_PAY_PAYMENTS:
      return {
        ...state,
        canMakeGooglePayPayments: true,
        googlePayBaseCardPaymentMethod: action.baseCardPaymentMethod,
        googlePayCardPaymentMethod: action.cardPaymentMethod,
        googlePayPaymentsClientHasBeenCreated: true,
      };

    case GOOGLE_PAY_PAYMENT_DATA_RECEIVED:
      return {
        ...state,
        googlePayPaymentData: action.paymentData,
        googlePayStubsModalOpen: false,
      };

    case GOOGLE_PAY_CANCEL:
      return {
        ...state,
        googlePayPaymentData: undefined,
        submitOrderError: {
          message: 'Your payment was cancelled',
          body: 'Please try again or try using another payment method',
        },
        invalidGooglePayBillingAddress: undefined,
        googlePayStubsModalOpen: false,
        paymentProcessing: false,
      };

    case `${GET_PAYPAL_CLIENT_TOKEN}.LOADING`:
      return {
        ...state,
        getPayPalClientTokenCallActive: true,
        getPayPalClientTokenFailed: false,
      };

    case `${GET_PAYPAL_CLIENT_TOKEN}.SUCCESS`:
      return {
        ...state,
        payPalClientToken: action.result?.token,
        getPayPalClientTokenCallActive: false,
        getPayPalClientTokenFailed: false,
      };

    case `${GET_PAYPAL_CLIENT_TOKEN}.FAILED`:
      return {
        ...state,
        getPayPalClientTokenCallActive: false,
        getPayPalClientTokenFailed: true,
        showPayPalServiceUnavailableError: action.showError,
        payPalServiceUnavailable: true,
        selectedPaymentType: null,
      };

    case SET_PAYPAL_SERVICE_UNAVAILABLE:
      return {
        ...state,
        payPalServiceUnavailable: true,
        selectedPaymentType: null,
        showPayPalServiceUnavailableError: true,
      };

    case SET_PAYPAL_PAYLATER_IS_NOT_ELIGIBLE:
      return {
        ...state,
        payPalPayLaterIsNotEligible: true,
      };

    case PAYPAL_PAYMENT_CANCEL:
      return {
        ...state,
        selectedPaymentType: undefined,
        ...!action?.isPaypalExpress && {
          submitOrderError: {
            code: errorCodeConstants.UI_SCROLL_TO_ERROR,
            message: 'Your PayPal payment was cancelled',
            body: 'Please try again or try using another payment method',
          },
        },
        invalidPayPalBillingAddress: undefined,
        invalidPayPalShippingAddress: undefined,
        paypalNonce: undefined,
        payPalPaymentBillingAddressRequest: undefined,
        payPalPaymentShippingAddressRequest: undefined,
        shippingOptionId: undefined,
      };

    case PAYPAL_PAYMENT_STARTED:
      return {
        ...state,
        payPalPaymentProcessing: true,
        payPalPaymentBillingAddressRequest: action.billingAddressRequest || state.payPalPaymentBillingAddressRequest,
        payPalPaymentShippingAddressRequest: action.shippingAddressRequest || state.payPalPaymentShippingAddressRequest,
      };

    case PAYPAL_PAYMENT_ENDED:
      return {
        ...state,
        payPalPaymentProcessing: false,
      };

    case SET_CAN_MAKE_PAYPAL_PAY_EXPRESS_PAYMENTS:
      return {
        ...state,
        payPalPayExpressCompatibilityChecksComplete: true,
      };

    case PAYPAL_STUBS_MODAL_OPEN:
      return {
        ...state,
        payPalStubsModalOpen: true,
      };

    case PAYPAL_STUBS_MODAL_CLOSE:
      return {
        ...state,
        payPalStubsModalOpen: false,
      };

    case GOOGLE_PAY_STUBS_MODAL_OPEN:
      return {
        ...state,
        googlePayStubsModalOpen: true,
      };

    case GOOGLE_PAY_WALLET_ELIGIBLE:
      return {
        ...state,
        googlePayWalletEligible: true,
      };

    case SHOW_POS_CREDIT_GET_READY_MODAL:
      return {
        ...state,
        showPOSGetReadyModal: true,
      };

    case CLOSE_POS_CREDIT_GET_READY_MODAL:
      return {
        ...state,
        paymentProcessing: false,
        showPOSGetReadyModal: false,
      };

    case `${POS_CREDIT_PAYMENT}.FAILED`: {
      const creditOptionsChanged = [
        errorCodeConstants.PAYMENT_POS_CREDIT_OPTIONS_CHANGED,
        errorCodeConstants.PAYMENT_POS_CREDIT_OPTIONS_CHANGED_WITH_ACTION,
        errorCodeConstants.PAYMENT_POS_CREDIT_OPTIONS_CHANGED_BASKET_INVALID,
      ].includes(action.error?.code ?? '');

      const submitOrderError = creditOptionsChanged ?
        getNonGlobalError({ error: { code: errorCodeConstants.PAYMENT_POS_CREDIT_OPTIONS_CHANGED } }) :
        getNonGlobalError({ error: { code: errorCodeConstants.CLIENT_POS_CREDIT_PAYMENT_FAILED } });

      return {
        ...state,
        paymentProcessing: false,
        partnerDiscountInvalid: action.error?.code === errorCodeConstants.PARTNER_CARD_NUMBER_INVALID,
        showPOSGetReadyModal: false,
        submitOrderError: {
          ...submitOrderError,
          isPosCreditError: true,
        },
      };
    }

    case `${APPLE_PAY_SUBMIT_PAYMENT_DATA}.FAILED`:
      return {
        ...state,
        showExpressPaymentsFailedMessage: true,
      };

    case SET_SELECTED_SAVED_CARD:
      return {
        ...state,
        selectedSavedCard: action.id,
      };

    case CREATE_PAYPAL_PAYMENT: {
      const selectedPaymentType = state.selectedPaymentType === paymentTypeConstants.PAYPAL_PAY_LATER ?
        paymentTypeConstants.PAYPAL_PAY_LATER :
        paymentTypeConstants.PAYPAL;
      return {
        ...state,
        selectedPaymentType,
      };
    }

    case CARD_TYPE_MISSING:
      return {
        ...state,
        submitOrderError: getNonGlobalError(
          { error: { code: errorCodeConstants.CLIENT_CARD_TYPE_MISSING } },
          state.submitOrderError,
        ),
      };

    case GOOGLE_SCRIPT_LOADED:
      return {
        ...state,
        googlePayScriptLoaded: true,
        googlePayScriptLoading: false,
      };

    case GOOGLE_SCRIPT_LOADING:
      return {
        ...state,
        googlePayScriptLoading: true,
      };

    case GOOGLE_SCRIPT_FAILED:
      return {
        ...state,
        googlePayScriptLoading: false,
        googlePayScriptFailed: true,
      };

    case IOVATION_SCRIPTS_LOADED:
      return {
        ...state,
        iovationScriptsLoaded: true,
        iovationScriptsLoading: false,
      };

    case IOVATION_SCRIPTS_LOADING:
      return {
        ...state,
        iovationScriptsLoading: true,
      };

    case IOVATION_SCRIPTS_FAILED:
      return {
        ...state,
        iovationScriptsLoading: false,
        iovationScriptsFailed: true,
      };

    case CLEARPAY_MESSAGE_SCRIPT_LOADED: {
      return {
        ...state,
        clearpayMessageScriptLoaded: true,
      };
    }

    case CLEARPAY_SCRIPT_LOADED:
      return {
        ...state,
        clearpayScriptLoaded: true,
        clearpayScriptLoading: false,
        clearpayServiceUnavailable: false,
      };

    case CLEARPAY_SCRIPT_LOADING:
      return {
        ...state,
        clearpayScriptLoading: true,
      };

    case CLEARPAY_SCRIPT_FAILED:
      return {
        ...state,
        clearpayServiceUnavailable: true,
        selectedPaymentType: null,
        clearpayScriptLoading: false,
        clearpayScriptLoaded: false,
      };

    case CLEARPAY_STUBS_MODAL_OPEN:
      return {
        ...state,
        clearpayStubsModalOpen: true,
      };

    case CLEARPAY_STUBS_MODAL_CLOSE:
      return {
        ...state,
        clearpayStubsModalOpen: false,
      };

    case CLEARPAY_SAVE_CARD: {
      const {
        cardNumber,
        cvc,
        expiryMonth,
        expiryYear,
      } = action.virtualCard;

      return {
        ...state,
        clearpayCardDetails: {
          type: paymentTypeConstants.CREDIT_CARD,
          cardNumber,
          securityCode: cvc,
          expiryDate: `${expiryMonth}/${expiryYear?.substring(2)}`,
        },
      };
    }

    case CLEARPAY_CANCEL: {
      if (state.clearpayError) {
        return state;
      }

      return {
        ...state,
        submitOrderError: {
          body: 'Please try again or try using another payment method',
          code: errorCodeConstants.UI_SCROLL_TO_ERROR,
          message: 'Your Clearpay payment was cancelled',
        },
        selectedPaymentType: undefined,
      };
    }

    case CLEARPAY_ERROR: {
      return {
        ...state,
        clearpayError: action.error,
        submitOrderError: {
          body: 'Please try again or try using another payment method',
          code: errorCodeConstants.UI_SCROLL_TO_ERROR,
          message: 'Sorry, your Clearpay payment was unsuccessful',
        },
        selectedPaymentType: undefined,
      };
    }

    case CLEARPAY_ENABLED_STATUS: {
      return {
        ...state,
        payOptionsAnalytics: {
          ...state.payOptionsAnalytics,
          [paymentTypeConstants.CLEARPAY]: {
            default: 0,
            enabled: action.enabled ? 1 : 0,
          },
        },
      };
    }

    case KLARNA_ENABLED_STATUS: {
      return {
        ...state,
        payOptionsAnalytics: {
          ...state.payOptionsAnalytics,
          [paymentTypeConstants.KLARNA]: {
            default: 0,
            enabled: action.enabled ? 1 : 0,
          },
        },
      };
    }

    case KLARNA_SCRIPT_LOADED:
      return {
        ...state,
        klarnaScriptLoaded: true,
        klarnaScriptLoading: false,
        klarnaServiceUnavailable: false,
      };

    case KLARNA_SCRIPT_LOADING:
      return {
        ...state,
        klarnaLoading: true,
        klarnaScriptLoading: true,
      };

    case KLARNA_SCRIPT_FAILED:
      return {
        ...state,
        klarnaScriptFailed: true,
        klarnaScriptLoading: false,
        klarnaScriptLoaded: false,
        klarnaServiceUnavailable: true,
        klarnaLoading: false,
        selectedPaymentType: null,
        submitOrderError: {
          body: 'Please try again or try using another payment method',
          code: errorCodeConstants.UI_SCROLL_TO_ERROR,
          message: "Sorry, we've been unable to connect to Klarna",
        },
      };

    case KLARNA_SDK_SCRIPT_LOADED:
      return {
        ...state,
        klarnaSdkScriptLoaded: true,
        klarnaSdkScriptLoading: false,
      };

    case KLARNA_SDK_SCRIPT_LOADING:
      return {
        ...state,
        klarnaSdkScriptLoading: true,
        klarnaSdkLoading: true,
      };

    case KLARNA_SDK_SCRIPT_FAILED:
      return {
        ...state,
        klarnaSdkLoading: false,
        klarnaSdkScriptFailed: true,
        klarnaSdkScriptLoading: false,
        klarnaSdkScriptLoaded: false,
      };

    case KLARNA_STUBS_MODAL_OPEN:
      return {
        ...state,
        klarnaStubsModalOpen: true,
      };

    case KLARNA_STUBS_MODAL_CLOSE:
      return {
        ...state,
        klarnaStubsModalOpen: false,
      };

    case KLARNA_SAVE_CARD: {
      const {
        pan,
        cvv,
        expiry_date,
      } = action.virtualCard;

      return {
        ...state,
        isKlarnaPaymentInProgress: false,
        klarnaCardDetails: {
          type: paymentTypeConstants.CREDIT_CARD,
          cardNumber: pan,
          securityCode: cvv,
          expiryDate: expiry_date,
        },
      };
    }

    case KLARNA_CANCEL: {
      if (state.klarnaError) {
        return state;
      }

      return {
        ...state,
        isKlarnaPaymentInProgress: false,
        submitOrderError: {
          body: 'Please try again or try using another payment method',
          code: errorCodeConstants.UI_SCROLL_TO_ERROR,
          message: 'Your Klarna payment was cancelled',
        },
        selectedPaymentType: undefined,
      };
    }

    case KLARNA_PAYMENT_STARTED: {
      return {
        ...state,
        isKlarnaPaymentInProgress: true,
      };
    }

    case KLARNA_PAYMENT_VISIBLE: {
      return {
        ...state,
        isKlarnaPaymentInProgress: action.visible,
      };
    }

    case KLARNA_LOAD_COMPLETE: {
      return {
        ...state,
        klarnaLoading: false,
      };
    }

    case KLARNA_ERROR: {
      return {
        ...state,
        klarnaError: action.error,
        selectedPaymentType: undefined,
        submitOrderError: {
          body: 'Please try again or try using another payment method',
          code: errorCodeConstants.UI_SCROLL_TO_ERROR,
          message: 'Sorry, your Klarna payment was unsuccessful',
        },
      };
    }

    case KLARNA_INITIALISE_ERROR: {
      return {
        ...state,
        klarnaScriptLoaded: false,
        klarnaServiceUnavailable: true,
        selectedPaymentType: undefined,
        submitOrderError: {
          body: 'Please try again or try using another payment method',
          code: errorCodeConstants.UI_SCROLL_TO_ERROR,
          message: "Sorry, we've been unable to connect to Klarna",
        },
      };
    }

    case EXPRESS_PAYMENTS_FAILED: {
      return {
        ...state,
        showExpressPaymentsFailedMessage: !action?.response?.validateAddressResult,
      };
    }

    case `${OCP_GET_SUBMISSION_SCRIPT}.SUCCESS`: {
      return {
        ...state,
        ocpTargetUrl: action.result.targetUrl,
      };
    }

    case `${OCP_GET_PAYMENT_ID}.SUCCESS`: {
      return {
        ...state,
        ocpPaymentId: action.result.id,
      };
    }

    case INIT_EXPRESS_PAYMENT: {
      return {
        ...state,
        isExpressPayment: true,
      };
    }

    case GIFT_OPTIONS_MODAL_CLOSE: {
      return {
        ...state,
        promoCodeError: null,
      };
    }

    case SHOW_EXPRESS_PAYMENTS: {
      if (action.shouldShow) {
        // in the future this might need to support google pay express/paypal express
        // Options set here will be overwritten on the payment page by the usual options
        // as express stops being available when that happens
        return {
          ...state,
          payOptionsAnalytics: action.payOptionsAnalytics,
        };
      }

      return state;
    }

    default:
      return state;
  }
}
