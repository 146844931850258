import isEmpty from 'lodash/isEmpty';

// Config
import { setSelectedPaymentType } from './paymentActions';
import { getDefaultPaymentCardOrFirst } from '../../reducers/user/userReducer';
import { DETERMINE_SELECTED_PAYMENT_TYPE } from '../../../constants/actionConstants';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';
import userConstants from '../../../constants/userConstants';
import { isBasketClearpayEligible } from '../../../utils/payment/isBasketClearpayEligible';
import { isBasketKlarnaEligible } from '../../../utils/payment/isBasketKlarnaEligible';
import isBasketPayPalPayLaterEligible from '../../../utils/payment/isBasketPayPalPayLaterEligible';
import isPayPalAvailable from '../../../utils/payment/isPayPalAvailable';

export function canSelectGooglePay(payment) {
  return payment.canMakeGooglePayPayments && payment.googlePayPaymentsClientHasBeenCreated;
}

export function paymentNoLongerEligible(isEligible, selectedPaymentType, paymentType) {
  return !isEligible && selectedPaymentType?.includes(paymentType);
}

export function posCreditNoLongerEligible(posCreditEligible, selectedPaymentType) {
  return paymentNoLongerEligible(posCreditEligible, selectedPaymentType, paymentTypeConstants.POS_CREDIT);
}

export function payPalPayLaterNoLongerEligible(payPalPayLaterEligible, selectedPaymentType) {
  return paymentNoLongerEligible(payPalPayLaterEligible, selectedPaymentType, paymentTypeConstants.PAYPAL_PAY_LATER);
}

export function clearpayNoLongerEligible(clearpayEligible, selectedPaymentType) {
  return paymentNoLongerEligible(clearpayEligible, selectedPaymentType, paymentTypeConstants.CLEARPAY);
}

export function klarnaNoLongerEligible(klarnaEligible, selectedPaymentType) {
  return paymentNoLongerEligible(klarnaEligible, selectedPaymentType, paymentTypeConstants.KLARNA);
}

export const checkPayLaterOptionsAvailable = () => (dispatch, getState) => {
  const { selectedPaymentType, posCreditEligible } = getState().payment;

  let selectNewPaymentType = false;

  if (selectedPaymentType?.includes(paymentTypeConstants.CLEARPAY)) {
    const { isEligible: clearpayEligible } = isBasketClearpayEligible(getState());
    if (clearpayNoLongerEligible(clearpayEligible, selectedPaymentType)) {
      selectNewPaymentType = true;
    }
  } else if (selectedPaymentType?.includes(paymentTypeConstants.KLARNA)) {
    const { isEligible: klarnaEligible } = isBasketKlarnaEligible(getState());
    if (klarnaNoLongerEligible(klarnaEligible, selectedPaymentType)) {
      selectNewPaymentType = true;
    }
  } else if (selectedPaymentType?.includes(paymentTypeConstants.PAYPAL_PAY_LATER)) {
    const payPalPayLaterEligible = isBasketPayPalPayLaterEligible(getState());
    if (payPalPayLaterNoLongerEligible(payPalPayLaterEligible, selectedPaymentType)) {
      selectNewPaymentType = true;
    }
  } else if (
    selectedPaymentType?.includes(paymentTypeConstants.POS_CREDIT) &&
    posCreditNoLongerEligible(posCreditEligible, selectedPaymentType)
  ) {
    selectNewPaymentType = true;
  }

  if (selectNewPaymentType) {
    return dispatch(setSelectedPaymentType());
  }

  return {};
};

export const priorityOrder = [
  paymentTypeConstants.APPLE_PAY,
  paymentTypeConstants.GOOGLE_PAY,
  paymentTypeConstants.SAVED_PAYMENT_CARD,
  paymentTypeConstants.CREDIT_CARD,
  paymentTypeConstants.PAYPAL,
];

export function getPayNowPriorityOrder(paymentMethodPreference) {
  const {
    APPLE_PAY,
    GOOGLE_PAY,
    SAVED_PAYMENT_CARD,
    PAYPAL,
  } = paymentTypeConstants;

  const {
    PAYMENT_PREFERENCE_PAYPAL,
    PAYMENT_PREFERENCE_APPLE_PAY,
    PAYMENT_PREFERENCE_GOOGLE_PAY,
    PAYMENT_PREFERENCE_SAVED_CARD,
  } = userConstants;

  if ([
    PAYMENT_PREFERENCE_PAYPAL,
    PAYMENT_PREFERENCE_APPLE_PAY,
    PAYMENT_PREFERENCE_GOOGLE_PAY,
    PAYMENT_PREFERENCE_SAVED_CARD,
  ].includes(paymentMethodPreference)) {
    const lookup = new Map();
    lookup.set(PAYMENT_PREFERENCE_APPLE_PAY, APPLE_PAY);
    lookup.set(PAYMENT_PREFERENCE_GOOGLE_PAY, GOOGLE_PAY);
    lookup.set(PAYMENT_PREFERENCE_SAVED_CARD, SAVED_PAYMENT_CARD);
    lookup.set(PAYMENT_PREFERENCE_PAYPAL, PAYPAL);

    // move the preferred payment method to the front of the list
    const mappedPreference = lookup.get(paymentMethodPreference);
    const found = priorityOrder.indexOf(mappedPreference);
    priorityOrder.unshift(priorityOrder.splice(found, 1)[0]);
  }

  return priorityOrder;
}

export const determineSelectedPaymentType = () => async (dispatch, getState) => {
  await dispatch(checkPayLaterOptionsAvailable());

  const {
    applePayCompatibilityChecksComplete,
    selectedPaymentType,
    paymentWallet = [],
  } = getState().payment;

  if (!selectedPaymentType) {
    let newSelectedPaymentType;

    const defaultSavedPaymentCard = getDefaultPaymentCardOrFirst(paymentWallet);

    const {
      APPLE_PAY,
      GOOGLE_PAY,
      SAVED_PAYMENT_CARD,
      CREDIT_CARD,
      PAYPAL,
    } = paymentTypeConstants;

    const priorityOrder = getPayNowPriorityOrder(getState().user?.customerPreferences?.paymentChoice);

    priorityOrder.forEach(((method) => {
      // Try and select each payment method in turn, setting newSelectedPaymentType if any succeed to stop trying others
      if (!newSelectedPaymentType) {
        if (method === APPLE_PAY && applePayCompatibilityChecksComplete) newSelectedPaymentType = method;
        if (method === GOOGLE_PAY && canSelectGooglePay(getState().payment)) newSelectedPaymentType = method;
        if (method === SAVED_PAYMENT_CARD && defaultSavedPaymentCard) newSelectedPaymentType = `${SAVED_PAYMENT_CARD}:${defaultSavedPaymentCard.id}`;
        if (method === CREDIT_CARD && isEmpty(paymentWallet)) newSelectedPaymentType = CREDIT_CARD;
        if (method === PAYPAL && isPayPalAvailable(getState())) newSelectedPaymentType = method;
      }
    }));

    if (newSelectedPaymentType) {
      dispatch({
        type: DETERMINE_SELECTED_PAYMENT_TYPE,
        selectedPaymentType: newSelectedPaymentType,
      });
    }
  }
};

export default determineSelectedPaymentType;
