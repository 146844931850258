import {
  useState,
  useEffect,
} from 'react';

//
import {
  selectInjectedHtml,
  fetchFragment,
  generateWrapperId,
} from '../../utils/esi/esi-utils';

import ClientESI from './ClientESI';

const ESIInclude = ({ src, id }: {
  id: string;
  src: string;
}) => {
  const wrapperId = generateWrapperId({ src, id });
  const html = selectInjectedHtml(wrapperId, id);
  const [htmlContent, setHtmlContent] = useState<string | null>(html);

  useEffect(() => {
    if (!htmlContent) {
      fetchFragment(src).then((fragment) => {
        setHtmlContent(fragment);
      });
    }
  }, [src]);

  if (htmlContent) return <ClientESI html={htmlContent} wrapperId={wrapperId} />;

  return null;
};

export default ESIInclude;
