import { v4 as uuidv4 } from 'uuid';
//
import getKountSDK from './getKountSDK';
import { getTargetBIN } from './getTargetBIN';
import { isFeatureActive } from '../../reducers/config/configReducer';
import featureConstants from '../../../constants/featureConstants';
import { recordImpressions } from '../app/appActions';

export const targetBINs = [{
  bin: '529930',
}, {
  bin: '535666',
}, {
  bin: '518652',
}, {
  bin: '552213',
}, {
  bin: '537410',
}, {
  bin: '537370',
}, {
  bin: '557483',
}, {
  bin: '552157',
}, {
  bin: '535522',
}, {
  bin: '492915',
}, {
  bin: '492181',
  featureSwitch: featureConstants.TRA_VISA_BINS,
}, {
  bin: '446291',
  featureSwitch: featureConstants.TRA_VISA_BINS,
}, {
  bin: '454313',
  featureSwitch: featureConstants.TRA_VISA_BINS,
}, {
  bin: '465944',
  featureSwitch: featureConstants.TRA_VISA_BINS,
}];

export const getTargetNumber = ({
  cardDetailsPayload,
  cardToBeStored,
  savedCardPayment,
}) => {
  if (savedCardPayment) return cardDetailsPayload?.cardNumber;

  if (!cardToBeStored) return cardDetailsPayload?.creditCardDetails?.number;

  return undefined;
};

export const shouldKount = params => (dispatch, getState) => {
  const {
    grandTotalRaw,
    cardDetailsPayload,
    cardToBeStored,
    savedCardPayment,
  } = params;

  if (grandTotalRaw < 440) {

    const number = getTargetNumber({
      cardDetailsPayload,
      cardToBeStored,
      savedCardPayment,
    });

    const targetBIN = getTargetBIN(number, targetBINs);

    if (targetBIN && (savedCardPayment || !cardToBeStored)) {
      const targetFeatureSwitch = targetBIN?.featureSwitch;

      // BIN is permanently going through kount
      if (!targetFeatureSwitch) return true;

      // BIN is being AB tested
      const state = getState();
      const {
        config: {
          features = [],
        } = {},
      } = state;

      const targetFeature = features?.find(
        feature => feature.id === targetFeatureSwitch,
      ) ?? {};

      const targetFeatureImpressionId = targetFeature.impressionId;
      if (targetFeatureImpressionId) dispatch(recordImpressions(targetFeatureImpressionId));

      if (isFeatureActive(state, targetFeatureSwitch)) return true;
    }
  }

  return false;
};

export function isTestEnvironment() {
  if (window.publicJLSiteDomain?.includes('johnlewis')) return false;

  return true;
}

export function getEnvironmentParam() {
  if (isTestEnvironment()) return 'TEST';

  return 'PROD';
}

export function generateKountSessionId() {
  return uuidv4().replace(/-/g, '');
}

export const initKount = params => dispatch => (
  new Promise((resolve) => {
    const {
      callbackFunction,
      callbackParams,
    } = params;

    if (!dispatch(shouldKount(params))) {
      resolve(dispatch(callbackFunction(callbackParams)));
    } else {
      let timeoutHappened = false;

      const timeout = setTimeout(() => {
        timeoutHappened = true;
        resolve(dispatch(callbackFunction(callbackParams)));
      }, 5000);

      const kountConfig = {
        clientID: '888840',
        environment: getEnvironmentParam(),
        isSinglePageApp: true,
        isDebugEnabled: isTestEnvironment(),
        callbacks: {
          'collect-end': (responseParams) => {
            if (!timeoutHappened) {
              clearTimeout(timeout);

              resolve(dispatch(callbackFunction({
                ...callbackParams,
                body: {
                  ...callbackParams.body,
                  fraudSessionId: responseParams?.SessionID,
                },
              })));
            }
          },
        },
      };

      const kountSDK = getKountSDK();

      const sdk = kountSDK(kountConfig, generateKountSessionId());
      if (!sdk) {
        // SDK failed for some reason so proceed to payment
        clearTimeout(timeout);
        resolve(dispatch(callbackFunction(callbackParams)));
      }
    }
  })
);

export default initKount;
