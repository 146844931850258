
// Design System
import env from 'jl-design-system/utils/env/env';

// Config
import ESIInclude from '../esi-include/ESIInclude';

// Types
import { ESIBannerProps } from './ESIBanner.types';

const ESIBanner = ({ id, type, url }: ESIBannerProps) => {
  let src = `${window.publicJLSiteDomain}${url}`;

  if (env.isClientNonProd && type) {
    src = `${window.location.origin}/stubs/esi/placeholder/${type}`;
  }

  return (
    <ESIInclude id={id} src={src} />
  );
};

export default ESIBanner;
