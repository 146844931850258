
// Types
import { BrandProps } from './Brand.types';
import { HeadingProps } from '../heading/Heading.types';

// Components
import Heading from '../heading';

// Styles
import styles from './brand.scss';

export const Brand = ({
  brand,
  headingTag = 'h4',
}: BrandProps) => {
  if (!brand) {
    return null;
  }

  const headingProps: HeadingProps = {
    marginBottom: '1',
    tag: headingTag,
    type: 'xs',
  };

  if (brand === 'John Lewis ANYDAY') {
    return <Heading {...headingProps}>John Lewis <span className={styles.anyday}>ANYDAY</span></Heading>;
  }

  return <Heading {...headingProps} label={brand} />;
};

export default Brand;
