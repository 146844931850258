
// Design System
import ProgressBar from 'jl-design-system/components/progress-bar/ProgressBar';

// Components
import MyJohnLewis from './MyJohnLewis';

// Styles
import styles from './my-john-lewis-full-screen.scss';

const MyJohnLewisFullScreen = () => (
  <>
    <ProgressBar
      activeStep={1}
      className={styles.progressBar}
      steps={[{
        label: 'Sign in details',
      }, {
        label: 'My John Lewis',
      }]}
    />

    <div className={styles.container}>
      <MyJohnLewis />
    </div>
  </>
);

export default MyJohnLewisFullScreen;
