
// Design System
import Link, { SecondaryLink } from 'jl-design-system/elements/link/Link';

// Types
import { MyAccountLinkProps } from './MyAccountLink.types';

const MyAccountLink = ({
  className,
  inline = false,
  isApps = false,
  level,
  text = 'My Account',
}: MyAccountLinkProps) => {
  if (isApps) {
    return text;
  }

  let link = '';

  if (window.publicJLSiteDomain.includes('johnlewis')) {
    link = '//account.johnlewis.com';
  }

  if (window.publicJLSiteDomain.includes('integration')) {
    link = '//account.integration.project4.com';
  }

  // can be extended in the future if we ever need to pass in primary etc.
  const LinkComponent = level === 'secondary' ? SecondaryLink : Link;

  return (
    <LinkComponent
      className={className}
      data-testid={`my-account-link-${level || 'link'}`}
      external
      inline={inline}
      target="_blank"
      to={link}
    >{ text }
    </LinkComponent>
  );
};

export default MyAccountLink;
