/* eslint-disable css-modules/no-unused-class */
/* eslint-disable css-modules/no-undef-class */
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// Design System
import AddIcon from 'jl-design-system/elements/icons-jb/Plus32PxOutlined';

// Types
import { AppDispatch } from 'types/RootState.types';
import { AddressBookModalPlaceholderProps } from './AddressBookModalPlaceholder.types';

// Styles
import styles from './address-book-modal.scss';

const AddressBookModalPlaceholder = ({
  disabled = false,
  id = '',
  onClick = () => {},
  path = '',
  text = '',
}: AddressBookModalPlaceholderProps) => {
  const dispatch: AppDispatch = useDispatch();

  const handleButtonClick = () => {
    dispatch(push(path));
    onClick();
  };

  return (
    <div>
      <button
        className={styles.placeholderContent}
        data-testid={id}
        disabled={disabled}
        onClick={handleButtonClick}
        type="button"
      >
        <AddIcon className={styles.placeholderIcon} />
        <span>{text}</span>
      </button>
    </div>
  );
};

export default AddressBookModalPlaceholder;
