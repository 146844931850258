import { ChangeEvent, memo } from 'react';

// Design System
import Checkbox from 'jl-design-system/components/checkbox/Checkbox';

// Types
import { CheckboxReduxWrapperProps } from './CheckboxReduxWrapper.types';

const CheckboxReduxWrapper = ({
  checkedValue,
  onBlur = () => {},
  onChange = () => {},
  ...checkboxProps
}: CheckboxReduxWrapperProps) => {
  const calcValue = (checked: boolean, checkedValue?: string) => (checked ? checkedValue : '');

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = calcValue(evt.target.checked, checkedValue);

    onChange(value);
  };

  const handleBlur = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = calcValue(evt.target.checked, checkedValue);

    onBlur(value);
  };

  return (
    <Checkbox {...checkboxProps} onBlur={handleBlur} onChange={handleChange} value={checkedValue} />
  );
};

export default memo(CheckboxReduxWrapper);
