import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import { createBlacklistFilter, createWhitelistFilter } from 'redux-persist-transform-filter';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage/session';
import createApiMiddleware from './createApiMiddleware';
import api from '../utils/api/api';
import rootReducer from './reducers';

import { ADDRESS_BOOK_ADDRESS_FORM_ID } from '../utils/form/configs/addressBook';
import { AGE_CHECK_FORM_ID } from '../utils/form/configs/ageCheck';
import { CARD_DETAILS_FORM_ID, PREPAID_CARD_DETAILS_FORM_ID } from '../utils/form/configs/cardDetails';

import { CLICK_AND_COLLECT_FORM_ID } from '../utils/form/configs/clickAndCollect';
import {
  APPLY_GIFT_CARD_FORM_ID,
  APPLY_GIFT_VOUCHER_FORM_ID,
} from '../utils/form/configs/giftOptions';
import { GUEST_FORM_ID } from '../utils/form/configs/login';
import {
  MY_JOHN_LEWIS_ADDRESS_FORM_SIMPLE_ID,
  MY_JOHN_LEWIS_ADDRESS_FORM_EDIT_ID,
  MY_JOHN_LEWIS_ADDRESS_FORM_NEW_ID,
} from '../utils/form/configs/myJohnLewis';
import { PROMO_CODE_FORM_ID } from '../utils/form/configs/promoCode';
import { SAVED_CARD_DETAILS_FORM_ID } from '../utils/form/configs/savedCardDetails';
import getCookieValue from '../utils/helpers/cookie/getCookie';
import appConstants from '../constants/appConstants';

export default function configureStore(history, initialState) {

  const reduxRouterMiddleware = routerMiddleware(history);
  const middleware = [
    createApiMiddleware(api),
    thunk,
    reduxRouterMiddleware,
  ];

  let enhancer;

  if (!window.publicJLSiteDomain?.includes('johnlewis') || getCookieValue('enhanced_logging')) {
    let devToolsExtension = f => f;
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
      devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__();
    }
    /* eslint-enable no-underscore-dangle */
    if (process.env.REACT_APP_REDUX_LOGGER !== 'false') {
      const logger = createLogger({
        collapsed: () => true,
      });
      middleware.push(logger);
    }
    enhancer = compose(
      applyMiddleware(...middleware),
      devToolsExtension,
    );
  } else {
    enhancer = applyMiddleware(...middleware);
  }

  // remove [these] keys from state.form
  const formFilter = createBlacklistFilter(
    'form',
    [
      ADDRESS_BOOK_ADDRESS_FORM_ID,
      AGE_CHECK_FORM_ID,
      CARD_DETAILS_FORM_ID,
      PREPAID_CARD_DETAILS_FORM_ID,
      CLICK_AND_COLLECT_FORM_ID,
      APPLY_GIFT_CARD_FORM_ID,
      APPLY_GIFT_VOUCHER_FORM_ID,
      GUEST_FORM_ID,
      MY_JOHN_LEWIS_ADDRESS_FORM_SIMPLE_ID,
      MY_JOHN_LEWIS_ADDRESS_FORM_EDIT_ID,
      MY_JOHN_LEWIS_ADDRESS_FORM_NEW_ID,
      PROMO_CODE_FORM_ID,
      SAVED_CARD_DETAILS_FORM_ID,
    ],
  );

  // remove [these] keys from state.confirmation
  const confirmationFilter = createBlacklistFilter(
    'confirmation',
    ['order'],
  );

  // remove [these] keys from state.app
  const appFilter = createBlacklistFilter(
    'app',
    [
      'isLoading',
      'loadingLazyComponent',
      'loadingMessage',
      'actionsLoaded',
      'firstRequestToApp',
      'showEmptyBasketNotification',
      'showConnectivityErrorModal',
      'showLeaveCheckout',
      'sessionExpiringAnnounced',
      'showSessionEndedModal',
      'handbackUrl',
      'auth0HealthCheckLoading',
      'rebatchOrderApiCallActive',
      'monetateScriptLoading',
      'monetateScriptLoaded',
      'gtmScriptAppended',
    ],
  );

  // remove [these] keys from state.delivery
  const deliveryFilter = createBlacklistFilter(
    'delivery',
    [
      'address',
      'applePayExpressConfig',
      'putDeliveryAddressError',
    ],
  );

  // remove [these] keys from state.clickAndCollect
  const clickCollectFilter = createBlacklistFilter(
    'clickAndCollect',
    [
      'removeCollectionPointOverlayVisible',
      'googleMapsScriptLoading',
      'googleMapsScriptLoaded',
    ],
  );

  // remove [these] keys from state.user
  const userFilter = createBlacklistFilter(
    'user',
    [
      'batchingFailureItems',
      'showBatchingFailureModal',
      'showBatchingFailureModal',
      'showRemoveBatchingFailureItemError',
      'yotiSrc',
    ],
  );

  // remove [these] keys from state.addressBook
  const addressBookFlter = createBlacklistFilter(
    'addressBook',
    [
      'loading',
      'removeAddressOverlayVisible',
    ],
  );

  // add only [these] keys from state.orderForm
  const orderFormFilter = createWhitelistFilter(
    'orderForm',
    [
      'amounts',
      'payments',
      'items',
      'confirmedCollectionPoint',
      'notAvailableForPartnerDiscountItems',
      'editBasketItemRemoveOverlayIds',
      'editBasketItemOutOfStockOverlayIds',
      'submitOrderFailed',
      'isClickCollectOrder',
      'promoCodeApplied',
      'useDeliveryAddressAsBillingAddress',
      'voucherUnderspend',
      'voucherUnderspendModalClose',
      'tradeInQuotes',
      'shouldApplyRewards',
      'rewardApplicable',
      'rewards',
      'getPaymentPageComplete',
      'orderDetails',
      'submitOrderFailureCode',
    ],
  );

  // add only [these] keys from state.payment
  const paymentFilter = createWhitelistFilter(
    'payment',
    [
      'applePayCompatibilityChecksComplete',
      'billingAddressFromPaypal',
      'shippingAddressFromPaypal',
      'canMakeApplePayPayments',
      'canMakeGooglePayPayments',
      'googlePayPaymentsClient',
      'googlePayWalletEligible',
      'payPalAuthorisationUnavailable',
      'payPalClientToken',
      'paymentWallet',
      'posCreditApplicationStatus',
      'selectedPaymentType',
      'selectedSavedCard',
      'showMarketingPreferencesPrompt',
      'showVoucherUnderspendWarningModal',
      'submitOrderApiCallActive',
      'payOptionsAnalytics',
      'isExpressPayment',
      'show3dSecureFrame',
      'show3dsMethodFrame',
      'creditCard3DSecureInfo',
      'threeDSMethodInfo',
    ],
  );

  // add [these] keys from state.config
  const configFilter = createWhitelistFilter(
    'config',
    [
      'auth0ClientConfiguration',
      'creditCardTypes',
      'applePayConfiguration',
      'sessionWarningAfterTime',
      'clickAndCollectCutOffWindowInMinutes',
      'googlePayConfiguration',
      'frontendKeys',
      'googlePayConfiguration',
    ],
  );

  // add [these] keys from state.bff
  const bffFilter = createWhitelistFilter(
    'bff',
    ['sessionId'],
  );

  // add only [these] keys from state.auth0Callback
  const auth0CallbackFilter = createWhitelistFilter(
    'auth0Callback',
  );

  // remove [getLoginPageComplete] keys from state.login
  const loginFilter = createBlacklistFilter(
    'login',
    ['getLoginPageComplete', 'showEmailValidationError'],
  );

  // remove [these] keys from state.mobileApps
  const mobileAppsFilter = createBlacklistFilter(
    'mobileApps',
    ['isRefreshAccessTokenRequested', 'refreshingAppsAccessToken'],
  );

  const persistConfig = {
    key: appConstants.ROOT,
    storage,
    stateReconciler: autoMergeLevel2,
    whitelist: [
      'app',
      'bff',
      'user',
      'config',
      'form',
      'clickAndCollect',
      'delivery',
      'deliveryAddress',
      'createAccount',
      'residentialAddress',
      'confirmation',
      'login',
      'orderForm',
      'addressBook',
      'payment',
      'mobileApps',
      'auth0Callback',
    ],
    transforms: [
      appFilter,
      formFilter,
      deliveryFilter,
      clickCollectFilter,
      confirmationFilter,
      orderFormFilter,
      paymentFilter,
      configFilter,
      loginFilter,
      bffFilter,
      userFilter,
      auth0CallbackFilter,
      addressBookFlter,
      mobileAppsFilter,
    ],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer(history));
  const store = createStore(persistedReducer, initialState, enhancer);
  const persistor = persistStore(store);

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (process.env.NODE_ENV === 'development' && module.hot) {
    console.log('configuring hot reload for reducers'); // eslint-disable-line no-console

    module.hot.accept('./reducers', () => {
      const nextRootReducer = store.replaceReducer(require('./reducers').default); // eslint-disable-line global-require
      store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
    });
  }

  return { store, persistor };
}
