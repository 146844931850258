// Types
import { AnalyticsState } from 'types/RootState.types';

// Config
import getConfig from '../utils/getConfig';
import getEventObject from '../utils/getEventObject';
import { getStorageItem } from '../../storage/storage';

export function updateWindowDataLayer(data: Record<string, unknown> | undefined) {
  // console.warn('---------- updateWindowDataLayer', data);
  if (data) {
    const citrusBannerAds = window?.jlData?.citrusBannerAds;
    window.jlData = {
      ...data,
      ...(citrusBannerAds && { citrusBannerAds }),
    };
  }
}

export function dispatchAnalyticsEvent(event: string = '', detail: Record<string, unknown> = {}) {
  if (getStorageItem('logAnalytics', true) === 'true') {
    /* eslint-disable no-console */
    console.warn('---------- DISPATCH ANALYTICS EVENT ----------');
    console.warn(`-- event: ${event} -- details:`, detail);
    console.warn(JSON.stringify(window.jlData, undefined, 2));
    /* eslint-enable no-console */
  }

  const triggerEvent = new window.CustomEvent(event, { detail });
  // eslint-disable-line no-undef
  document.body.dispatchEvent(triggerEvent);
}

/**
 * Checks if analytics event for a given redux Action should be triggered
 * If TRUE, returns the event object that will be used to update the window.jlData and trigger the event
 * If FALSE, returns the existing state.analyticsData
 * @param action {object} : redux action
 * @param state {object} : redux state.analytics
 * @param analytics {object} : analytics object returned from api or included in custom analytics action
 * @returns {object} : { event, details, data } || { data: state.analyticsData }
 */
export function getAnalyticsEventObject({
  action = {},
  analytics = {},
  replaceAnalyticsData = false,
  state = {},
}: {
  action?: Record<string, unknown>;
  analytics?: Record<string, unknown>;
  replaceAnalyticsData?: boolean;
  state?: AnalyticsState;
}) {
  const route = state.route;
  const config = getConfig({ ...action, route });
  // console.warn('getAnalyticsEventObject CONFIG:', config);

  const { event, details, data } = getEventObject({ action, state, config, analytics, replaceAnalyticsData });

  return config ? { event, details, data } : { data };
}

/**
 * Checks if analytics event for a given redux Action should be triggered
 * @param event {string} : 'eventName' eg: new CustomEvent('eventName');
 * @param details {object} : optional param for the analytics CustomEvent()
 *                      eg: new CustomEvent('eventName', { detail: {...details} });
 * @param data {object} : the latest analytics data used to update window.jlData
 */
export default function analyticsTrigger({
  data,
  details,
  event,
}: {
  data?: Record<string, unknown>;
  details?: Record<string, unknown>;
  event?: string;
}) {
  /* eslint-disable no-console */
  if (!event) {
    // console.warn('---------- NO ANALYTICS EVENT OBJECT : NO TRIGGER ----------');
    return;
  }

  updateWindowDataLayer(data);

  if (document.readyState === 'complete') {
    // console.warn('---- DOC READY ----');
    dispatchAnalyticsEvent(event, details);
  } else {
    document.onreadystatechange = () => {
      // console.warn('---- ON DOC READY STATE CHANGE ----');
      // console.warn(document.readyState, event, details);
      if (document.readyState === 'complete') {
        dispatchAnalyticsEvent(event, details);
      }
    };
  }
  /* eslint-enable no-console */
}
