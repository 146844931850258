import { createSelector } from 'reselect';

// Types
import { RootState } from 'types/RootState.types';

// Config
import { PAYMENT_BLOCKERS } from '../../components/payment-option-unavailable/PaymentOptionUnavailable.config';
import featureConstants from '../../constants/featureConstants';
import { isFeatureActive } from '../../redux/reducers/config/configReducer';
import { getDeliveriesWithClearabee } from '../../redux/reducers/delivery/deliverySelector';
import {
  getOutstandingBalanceRawSelector,
  orderFormBasketContainsAlcoholSelector,
} from '../../redux/reducers/order-form/orderFormSelector';

export const shouldShowKlarnaFeature = (state: RootState): boolean =>
  !isFeatureActive(state, featureConstants.DISABLE_KLARNA);

export type KlarnaEligibilityType = {
  isEligible: boolean;
  reasons: (typeof PAYMENT_BLOCKERS)[keyof typeof PAYMENT_BLOCKERS][];
};

export const KLARNA_MIN_SPEND = 30;
export const KLARNA_MAX_SPEND = 2000;

export const getBasketContainsAlcoholSelector = (state: RootState) => orderFormBasketContainsAlcoholSelector(state);

export const selectKlarnaIneligibilityReasons = createSelector(
  [getDeliveriesWithClearabee, getOutstandingBalanceRawSelector, getBasketContainsAlcoholSelector],
  (deliveriesWithClearabee = [], outstandingBalanceRaw, basketContainsAlcohol) => {
    const reasons: (typeof PAYMENT_BLOCKERS)[keyof typeof PAYMENT_BLOCKERS][] = [];

    if (basketContainsAlcohol) {
      reasons.push(PAYMENT_BLOCKERS.BASKET_CONTAINS_ALCOHOL);
    }
    if (deliveriesWithClearabee.length > 0) {
      reasons.push(PAYMENT_BLOCKERS.CLEARABEE_DELIVERIES);
    }
    if (Number(outstandingBalanceRaw) < KLARNA_MIN_SPEND) {
      reasons.push(PAYMENT_BLOCKERS.BALANCE_BELOW_MIN);
    }
    if (Number(outstandingBalanceRaw) > KLARNA_MAX_SPEND) {
      reasons.push(PAYMENT_BLOCKERS.BALANCE_ABOVE_MAX);
    }

    return reasons;
  },
);

export const isBasketKlarnaEligible = createSelector(
  [shouldShowKlarnaFeature, selectKlarnaIneligibilityReasons],
  (shouldShowKlarnaFeature, reasons) => ({
    isEligible: shouldShowKlarnaFeature && reasons.length === 0,
    reasons,
  }),
);
