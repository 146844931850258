import {
  useRef,
  useEffect,
  useMemo,
} from 'react';

//
import { separateScriptTags } from '../../utils/esi/esi-utils';

const ClientESI = ({ html, wrapperId }: {
  html: string;
  wrapperId: string;
}) => {
  const el = useRef<HTMLDivElement>(null);
  const {
    htmlWithoutScripts,
    scripts,
  } = useMemo(
    () => separateScriptTags(html),
    [html],
  );

  useEffect(() => {
    const currentEl = el.current;

    if (currentEl) {
      scripts.forEach((script: HTMLScriptElement) => currentEl.appendChild(script));
    }

    return () => {
      if (currentEl) {
        scripts.forEach((script: HTMLScriptElement) => {
          if (currentEl.contains(script)) {
            currentEl.removeChild(script);
          }
        });
      }
    };
  }, [scripts]);

  return (
    <div
      ref={el}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: htmlWithoutScripts }}
      id={wrapperId}
    />
  );
};

export default ClientESI;
