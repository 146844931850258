// Please note that to enable features or switches they must
// also be added to features.yml in the checkout code base
export default {
  ALLOW_MY_JL_SIGN_UPS: 'join_my_jl',
  ALWAYS_SHOW_ADD_CARD_RADIO: 'always_show_add_card_radio',
  APPLE_PAY_EXPRESS_ENHANCED: 'apple_pay_express_enhanced',
  APPLE_PAY_EXPRESS_SIGNED_IN: 'apple_pay_express_signed_in',
  APPLE_PAY_WITHOUT_SAVED_CARD: 'apple_pay_without_saved_card',
  CAMPAIGN_BANNER: 'campaign_banner',
  COLLECTION_UNAVAILABLE_TAB: 'collection_unavailable_tab',
  CONDENSED_COLLECTION: 'condensed_collection',
  CONDENSED_DELIVERY: 'condensed_delivery',
  DEFAULT_DELIVERY_ADDRESS: 'default_delivery_address',
  DISABLE_APPLE_PAY: 'disable_apple_pay',
  DISABLE_CLEARPAY: 'disable_clearpay',
  DISABLE_GOOGLE_PAY: 'disable_google_pay',
  DISABLE_KLARNA: 'disable_klarna',
  DISABLE_KOUNT: 'disable_kount',
  DISABLE_PAYPAL: 'disable_paypal',
  DISABLE_YOTI: 'disable_yoti',
  ENABLE_PAYPAL_EXPRESS: 'enable_paypal_express',
  EXPLAIN_BASKET_RESTICTIONS: 'explain_basket_restrictions',
  FLOWERS_PROPOSITION_DISABLED: 'flowers_proposition_disabled',
  FORCE_CUSTOMER_APP_TYPE: 'force_customer_app_type',
  FREE_CLICK_COLLECT: 'free_click_collect',
  LOG_ANALYTICS: 'logAnalytics',
  MEMBERS_WEEK_MESSAGING: 'members_week_messaging',
  NEW_ADDRESS_BOOK_LAYOUT: 'new_address_book_layout',
  OPEN_PROMO_FIELD: 'open_promo_field',
  OUTLET_DELIVERY_DATES: 'outlet_delivery_dates',
  PAYMENT_PAGE_BANNER_ENABLED: 'payment_page_banner_enabled',
  PAYPAL_CLIENT_TOKEN_OVERRIDE: 'payPalClientTokenOverride',
  POSC_BASKET_EXCESS: 'posc_basket_excess',
  PREFERRED_DELIVERY_CHOICE :'preferred_delivery_choice',
  PRICE_PROMISE: 'price_promise',
  RAVELINJS_DISABLED: 'ravelinjs_disabled',
  RECOMMENDATIONS_SERVERSIDE_FETCH: 'recommendations_serverside_fetch',
  SEPARATED_POSC_OPTIONS: 'separated_posc_options',
  SHOW_CALL_CENTRE_NUMBER_ON_3DS_ERROR: 'show_call_centre_number_on_3ds_error',
  SHOW_RECOMMENDATIONS: 'show_recommendations',
  STRIPE_CHECKOUT_ENABLED: 'stripe_checkout_enabled',
  TEST_CRYPTOGRAM_IN_INTEGRATION: 'test_cryptogram_in_integration',
  THRESHOLD_BAR: 'threshold_bar',
  TRA_VISA_BINS: 'tra_visa_bins',
  USE_LOCAL_PAYPAL_SANDBOX_OVERRIDE: 'use_local_paypal_sandbox_override',
  USE_REAL_CLEARPAY: 'use_real_clearpay',
  USE_REAL_KLARNA: 'use_real_klarna',
  USE_REAL_LOQATE_OVERRIDE: 'use_real_loqate_override',
  USE_STUBBED_NEWRELIC: 'use_stubbed_newrelic',
};
