// Types
import { RootState } from 'types/RootState.types';

import {
  getAppSessionExpiredSelector,
  getAppPosCreditSessionExpiredSelector,
  getAppSubmitOrderTimeoutSelector,
  getAppShowEmptyBasketNotificationSelector,
} from '../../redux/reducers/app/appSelector';
import { getErrorSelector } from '../../redux/reducers/error/errorSelector';

export const shouldShowUserActiveContent = (state: RootState) => {
  const sessionExpired = getAppSessionExpiredSelector(state);
  const posCreditSessionExpired = getAppPosCreditSessionExpiredSelector(state);
  const globalError = getErrorSelector(state);
  const submitOrderTimeout = getAppSubmitOrderTimeoutSelector(state);
  const showEmptyBasketNotification = getAppShowEmptyBasketNotificationSelector(state);

  return !sessionExpired
    && !posCreditSessionExpired
    && !globalError
    && !submitOrderTimeout
    && !showEmptyBasketNotification;
};
