// lodash
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
// jl-design-system
import validate from 'jl-design-system/form/validation/validate';
import env from 'jl-design-system/utils/env/env';
// constants
import {
  ANALYTICS_OPEN_APPLE_PAY_PAYMENT_SHEET,
  APPLE_PAY_BILLING_ADDRESS_MISSING,
  APPLE_PAY_PAYMENT,
  APPLE_PAY_PAYMENT_CANCEL,
  APPLE_PAY_PAYMENT_FAILURE,
  APPLE_PAY_REQUEST_PAYMENT_SESSION,
  APPLE_PAY_SET_CARD_TYPE,
  APPLE_PAY_SUBMIT_PAYMENT_DATA,
  SET_APPLE_PAY_PAYMENT_REQUEST,
  SET_CAN_MAKE_APPLE_PAY_PAYMENTS,
  UPDATE_BILLING_ADDRESS,
  EXPRESS_PAYMENTS_VALIDATION_FAILED,
  ANALYTICS_OPEN_APPLE_PAY_EXPRESS_PAYMENT_SHEET,
  GET_APPLE_PAY_EXPRESS_PAYMENT_REQUEST,
  SET_APPLE_PAY_ENHANCED_SHIPPING_IDENTIFIER,
} from '../../../constants/actionConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import { isApps, isIosApp, isWeb } from '../../reducers/app/appReducer';
import {
  getBillingAddress,
  getFormattedAddressFromApplePay,
  mapAddressValidationErrorToApplePayErrors,
} from '../../../utils/address/addressHelpers';

import {
  URL_CREATE_APPLE_PAY_PAYMENT,
  URL_OCP_SUBMIT_CARD_DETAILS,
  URL_REQUEST_APPLE_PAY_SESSION,
  URL_APPLE_PAY_EXPRESS_REQUEST,
} from '../../../constants/endpointConstants';
import { updateBillingAddress } from './cardPaymentActions';
import {
  setPaymentProcessing,
  triggerTransactionStatusAnalytics,
  triggerPlaceOrderAndPayAnalytics,
} from './paymentActions';
import { submitOrderAndRedirect } from './submitOrderActions';
import { triggerAnalyticsEvent } from '../analytics/analyticsAction';
import {
  handleApplePayExpress,
  handleExpressFailure,
  setPartialDeliveryAddress,
  cleanupExpressBasket,
} from './expressPaymentActions';
import paymentStatusConstants from '../../../constants/paymentStatusConstants';
import getBillingDetailsFormConfig from '../../../utils/form/configs/billingAddress';
import { initPaymentPage } from './orderFormActions';
import { sendNewRelicCustomEvent, logToConsole } from '../../../utils/logging/logging-utils';
import featureConstants from '../../../constants/featureConstants';
import { isFeatureActive } from '../../reducers/config/configReducer';

const canMakeApplePayPaymentsOnWeb = async (state) => {
  const applePayVersion = get(state.config.applePayConfiguration, 'applePayMethod.data.version');
  const merchantIdentifier = get(state.config.applePayConfiguration, 'applePayMethod.data.merchantIdentifier');
  if (window.ApplePaySession && window.ApplePaySession.supportsVersion(applePayVersion)) {
    const enableApplePayWithoutSavedCard = isFeatureActive(state, featureConstants.APPLE_PAY_WITHOUT_SAVED_CARD);

    if (enableApplePayWithoutSavedCard) return window.ApplePaySession.canMakePayments(merchantIdentifier);

    return window.ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);
  }
  return false;
};

export const setCanMakeApplePayPayments = () => (dispatch, getState) => {
  if (isFeatureActive(getState(), featureConstants.DISABLE_APPLE_PAY)) return;

  dispatch({
    type: SET_CAN_MAKE_APPLE_PAY_PAYMENTS,
  });
};

export const setApplePayCardType = (cardType = '', express) => async (dispatch, getState) => {
  const state = getState();
  const billingAddress = state.payment?.billingAddress;
  const nameOnCard = `${billingAddress.addressee?.firstName} ${billingAddress.addressee?.lastName}`;

  return dispatch({
    type: APPLE_PAY_SET_CARD_TYPE,
    request: client => client({
      path: URL_CREATE_APPLE_PAY_PAYMENT,
      config: {
        method: 'PATCH',
        body: {
          cardType,
          nameOnCard,
          ...express && { express },
        },
      },
    }),
  });
};

export const fixExpressCancelStubs = (express) => {
  if (
    express &&
    env.isClientNonProd &&
    window.PaymentWallet?.state === 'cancelled'
  ) {
    window.PaymentWallet.state = 'created';
  }
};

export const cancelApplePayPayment = (error, express) => (dispatch, getState) => {
  const state = getState();
  const enhancedFeatureActive = isFeatureActive(state, featureConstants.APPLE_PAY_EXPRESS_ENHANCED);

  logToConsole(error);
  sendNewRelicCustomEvent('applePayCancelled', {
    error,
    sessionId: state?.bff?.sessionId,
  });

  dispatch({ type: APPLE_PAY_PAYMENT_CANCEL, express, ...enhancedFeatureActive && { enhancedFeatureActive } });

  if (express && enhancedFeatureActive) dispatch(cleanupExpressBasket());

  fixExpressCancelStubs(express);

  if (!express) dispatch(initPaymentPage());
};

export const failureApplePayPayment = (error, express) => (dispatch, getState) => {
  const state = getState();
  const enhancedFeatureActive = isFeatureActive(state, featureConstants.APPLE_PAY_EXPRESS_ENHANCED);

  logToConsole(error);
  sendNewRelicCustomEvent('applePayFailed', {
    error,
    sessionId: state?.bff?.sessionId,
  });
  dispatch({ type: APPLE_PAY_PAYMENT_FAILURE, express, ...enhancedFeatureActive && { enhancedFeatureActive }  });
  if (express && enhancedFeatureActive) dispatch(cleanupExpressBasket());
};

export const canMakeApplePayPayments = () => async (dispatch, getState) => {
  const state = getState();
  const applePayCompatibilityChecksComplete = get(state.payment, 'applePayCompatibilityChecksComplete');

  if (applePayCompatibilityChecksComplete || isFeatureActive(state, featureConstants.DISABLE_APPLE_PAY)) {
    return;
  }

  if (isWeb(state) && await canMakeApplePayPaymentsOnWeb(state).catch(() => false)) {
    dispatch(setCanMakeApplePayPayments());
  } else if (isIosApp(state)) {
    try {
      const supportedNetworks = get(state.config.applePayConfiguration, 'applePayMethod.data.supportedNetworks');
      const canMakeApplePayPaymentsUsingNetworksHandler = get(window.webkit.messageHandlers,
        'canMakeApplePayPaymentsUsingNetworksHandler');
      if (canMakeApplePayPaymentsUsingNetworksHandler) {
        canMakeApplePayPaymentsUsingNetworksHandler.postMessage(supportedNetworks);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Error: canMakeApplePayPaymentsUsingNetworksHandler.postMessage()', err);
    }
  }
};

const requestPaymentSession = (body, hostUrl, headers) => ({
  type: APPLE_PAY_REQUEST_PAYMENT_SESSION,
  request: client => client({
    path: URL_REQUEST_APPLE_PAY_SESSION,
    config: {
      method: 'POST',
      headers,
      body,
    },
    hostUrl,
  }),
});

const submitApplePayPaymentData = (paymentId, body, hostUrl, headers) => ({
  type: APPLE_PAY_SUBMIT_PAYMENT_DATA,
  request: client => client({
    path: URL_OCP_SUBMIT_CARD_DETAILS(paymentId),
    config: {
      method: 'PATCH',
      headers,
      body,
    },
    hostUrl,
  }),
});

const merchantSessionPromise = (event, dispatch, state, express) => new Promise(
  async (resolve, reject) => {
    const host = get(state.orderForm, 'ocpIntegration.baseUrl');
    const sessionId = get(state, 'bff.sessionId', '');
    const orderFormId = get(state, 'orderForm.id', '');
    const omsOrderId = get(state, 'orderForm.omsOrderId', '');
    const {
      displayName,
      initiative,
      initiativeContext,
      merchantIdentifier,
    } = get(state.config, 'applePayConfiguration.merchantValidationData', {});

    const body = {
      displayName,
      initiative,
      initiativeContext,
      merchantIdentifier,
      validationUrl: event.validationURL,
    };

    const headers = {
      JSESSIONID: sessionId,
      'JL-BASKET-ID': orderFormId,
      'JL-OMS-ORDER-ID': omsOrderId,
      'JL-CALLING-APP-V1': 'OCC',
      'Accept-Language': '*',
    };

    const postLocation = `${host}/${URL_REQUEST_APPLE_PAY_SESSION}`;

    const { type, result = {}, error = {} } = await dispatch(requestPaymentSession(body, host, headers));

    if (type === `${APPLE_PAY_REQUEST_PAYMENT_SESSION}.SUCCESS`) {
      sendNewRelicCustomEvent('applePaySessionResponse', {
        statusCode: 200,
        orderFormId,
        postLocation,
        sessionId,
      });
      resolve(result);
    } else {
      sendNewRelicCustomEvent('applePaySessionResponse', {
        ...error,
        orderFormId,
        postLocation,
        sessionId,
      });
      dispatch({ type: APPLE_PAY_PAYMENT_FAILURE, express });
      reject();
    }
  },
);

const submitApplePayPayment = (billingAddress, paymentResponse, express) => async (dispatch, getState) => {
  const shouldRequestBillingAddressFromApplePay = !isApps(getState());

  if (billingAddress) {
    const validateAddressResult = validate({
      ...billingAddress.address,
      ...billingAddress.addressee,
      phoneNumber: billingAddress.phoneNumber,
    }, {
      config: getBillingDetailsFormConfig({
        countryCode: billingAddress.address?.countryCode,
        actions: {},
        enableGBCounty: !!billingAddress.address?.countyStateOrProvince,
      }),
    });

    if (!isEmpty(validateAddressResult)) {
      return {
        validateAddressResult,
      };
    }

    const { type } = await dispatch(updateBillingAddress({
      body: billingAddress,
      shouldRequestBillingAddressFromApplePay,
      isApplePaySelected: true,
    }));

    if (type === `${UPDATE_BILLING_ADDRESS}.FAILED`) {
      return {};
    }
  }

  const createApplePayPaymentResponse = await dispatch({
    type: APPLE_PAY_PAYMENT,
    request: client => client({
      path: URL_CREATE_APPLE_PAY_PAYMENT,
      config: { method: 'POST' },
    }),
  });

  await dispatch(triggerPlaceOrderAndPayAnalytics(createApplePayPaymentResponse?.result?.id));

  if (!isApps(getState()) && createApplePayPaymentResponse.type === `${APPLE_PAY_PAYMENT}.SUCCESS`) {
    const setCardTypeResponse =
      await dispatch(setApplePayCardType(
        paymentResponse?.details?.token?.paymentMethod?.network,
        express,
      ));
    if (setCardTypeResponse.type === `${APPLE_PAY_SET_CARD_TYPE}.FAILED`) {
      return setCardTypeResponse;
    }
  }

  return createApplePayPaymentResponse;
};

const triggerOpenApplePayPaymentSheetEvent = ({ express = false } = {}) => async (dispatch, getState) => {
  dispatch(triggerAnalyticsEvent(express
    ? ANALYTICS_OPEN_APPLE_PAY_EXPRESS_PAYMENT_SHEET
    : ANALYTICS_OPEN_APPLE_PAY_PAYMENT_SHEET, {
    payment: {
      payment: {
        ...get(getState().analytics, 'analyticsData.checkout.payment.order'),
      },
    },
  }));
};

const submitApplePayToken = token => async (dispatch, getState) => {
  const state = getState();
  const paymentId = get(state.payment, 'ocpPaymentId');
  const host = get(state.orderForm, 'ocpIntegration.baseUrl');
  const sessionId = get(state, 'bff.sessionId', '');
  const orderFormId = get(state, 'orderForm.id', '');
  const omsOrderId = get(state, 'orderForm.omsOrderId', '');
  const body = {
    method: 'applePay',
    applePayData: window.btoa(JSON.stringify(token)),
  };

  const headers = {
    JSESSIONID: sessionId,
    'JL-BASKET-ID': orderFormId,
    'JL-OMS-ORDER-ID': omsOrderId,
    'JL-CALLING-APP-V1': 'OCC',
    Accept: 'application/vnd.jl-payments-v3+json',
    'Content-Type': 'application/vnd.jl-payments-v3+json',
    'Accept-Language': '*',
  };

  const submitAction = await dispatch(submitApplePayPaymentData(paymentId, body, host, headers));

  const {
    type,
    error: { code, authenticationStatus: authenticationStatusOnError } = {},
    result: { status, authenticationStatus: authenticationStatusOnSuccess } = {},
  } = submitAction;

  if (type === `${APPLE_PAY_SUBMIT_PAYMENT_DATA}.SUCCESS`) {
    dispatch(triggerTransactionStatusAnalytics(status, { authenticationStatus: authenticationStatusOnSuccess }));
  }

  if (type === `${APPLE_PAY_SUBMIT_PAYMENT_DATA}.FAILED`) {
    dispatch(triggerTransactionStatusAnalytics('FAILED', {
      errorCode: code,
      authenticationStatus: authenticationStatusOnError,
    }));
  }

  return submitAction;
};

export const submitApplePayTokenAndOrder = token => async (dispatch) => {
  const submitApplePayTokenResponse = await dispatch(submitApplePayToken(token));
  const { type, result: { status } = {} } = submitApplePayTokenResponse;

  if (type === `${APPLE_PAY_SUBMIT_PAYMENT_DATA}.SUCCESS`
    && status === paymentStatusConstants.PENDING_AUTHORISATION) {
    dispatch(setPaymentProcessing(false));
    dispatch(submitOrderAndRedirect());
  } else {
    dispatch(failureApplePayPayment(submitApplePayTokenResponse));
  }
};

export const handlePaymentResponse = ({
  response,
  isResubmitting = false,
  express,
} = {}) => async (dispatch, getState) => {
  const applePayPaymentRequest = response || getState().payment.applePayPaymentRequest;
  const billingContact = get(applePayPaymentRequest, 'details.billingContact');
  const payerPhone = get(applePayPaymentRequest, 'payerPhone');

  if (response) {
    if (express) {
      const shippingContact = get(applePayPaymentRequest, 'details.shippingContact');
      const applePayEnhancedShippingIdentifier = getState().delivery?.applePayEnhancedShippingIdentifier;

      const handleApplePayExpressResponse = await dispatch(handleApplePayExpress({
        shippingContact,
        payerPhone,
        ...applePayEnhancedShippingIdentifier && {
          selectedShippingOptionIdentifier: applePayEnhancedShippingIdentifier,
        },
      }));

      const {
        response: {
          type: handleApplePayExpressResponseType,
          validateAddressResult,
        } = {},
      } = handleApplePayExpressResponse;

      if (handleApplePayExpressResponseType === EXPRESS_PAYMENTS_VALIDATION_FAILED) {
        if (validateAddressResult) {
          const errors = mapAddressValidationErrorToApplePayErrors({
            validationErrors: validateAddressResult,
            errorKey: 'shippingContactInvalid',
          });

          if (!isEmpty(errors?.paymentMethod)) {
            applePayPaymentRequest.retry(errors).then(() => {
              dispatch(handlePaymentResponse({ response: applePayPaymentRequest, express }));
            }).catch((error) => {
              applePayPaymentRequest.complete('fail');
              dispatch(cancelApplePayPayment(error, express));
            });
          }
        } else {
          dispatch(cancelApplePayPayment(handleApplePayExpressResponse, express));
          applePayPaymentRequest.complete('fail');
        }

        return;
      }
    }

    await dispatch({
      type: SET_APPLE_PAY_PAYMENT_REQUEST,
      paymentRequest: response,
    });
  }

  const shouldRequestBillingAddressFromApplePay = !isApps(getState());

  let billingAddress;

  if (
    shouldRequestBillingAddressFromApplePay &&
    !isEmpty(billingContact) &&
    payerPhone &&
    !isResubmitting
  ) {
    billingAddress = getFormattedAddressFromApplePay(billingContact, payerPhone);
  } else {
    billingAddress = getBillingAddress(getState());
  }

  if (!billingAddress || isEmpty(billingAddress)) {
    const sessionId = get(getState(), 'bff.sessionId', '');
    const orderFormId = get(getState(), 'orderForm.id', '');

    sendNewRelicCustomEvent('applePayBillingAddressMissing', {
      orderFormId,
      sessionId,
      isApps: isApps(getState()),
    });

    dispatch({
      type: APPLE_PAY_BILLING_ADDRESS_MISSING,
      error: {
        code: errorCodeConstants.CLIENT_APPLE_PAY_BILLING_ADDRESS_MISSING,
      },
    });

    if (express) {
      logToConsole(APPLE_PAY_BILLING_ADDRESS_MISSING);
      dispatch(handleExpressFailure(response));
    }
    return;
  }

  const submitApplePayPaymentResponse = await dispatch(submitApplePayPayment(
    billingAddress,
    applePayPaymentRequest,
    express,
  ));

  if (shouldRequestBillingAddressFromApplePay) {
    const errors = mapAddressValidationErrorToApplePayErrors({
      validationErrors: submitApplePayPaymentResponse.validateAddressResult,
      errorKey: 'billingContactInvalid',
    });

    if (!isEmpty(errors?.paymentMethod)) {
      applePayPaymentRequest.retry(errors).then(() => {
        dispatch(handlePaymentResponse({ response: applePayPaymentRequest }));
      }).catch((error) => {
        dispatch(cancelApplePayPayment(error, express));
      });

      return;
    }
  }

  const { type } = submitApplePayPaymentResponse;

  if (type === `${APPLE_PAY_SET_CARD_TYPE}.FAILED`) {
    dispatch(failureApplePayPayment(submitApplePayPaymentResponse, express));
    applePayPaymentRequest.complete('fail');
    return;
  }

  if (type === `${APPLE_PAY_PAYMENT}.SUCCESS`) {
    const { type, result: { status } = {} } = await dispatch(submitApplePayToken(applePayPaymentRequest.details.token));

    if (type === `${APPLE_PAY_SUBMIT_PAYMENT_DATA}.SUCCESS`
      && status === paymentStatusConstants.PENDING_AUTHORISATION) {
      await applePayPaymentRequest.complete('success');
      dispatch(setPaymentProcessing(false));
      dispatch(submitOrderAndRedirect({ express }));
      return;
    }
  }

  applePayPaymentRequest.complete('fail');
  dispatch(failureApplePayPayment(undefined, express));
};

const expressPaymentRequestPayload = async (state, dispatch) => {
  let applePayExpressPaymentResponse;
  const enhancedFeatureActive = isFeatureActive(state, featureConstants.APPLE_PAY_EXPRESS_ENHANCED);

  if (enhancedFeatureActive) {
    applePayExpressPaymentResponse = await dispatch(getApplePayExpressPaymentRequest());
  }

  const {
    config: {
      applePayConfiguration: {
        applePayMethod,
      } = {},
    } = {},
    orderForm: {
      amounts: {
        basketTotalRaw,
        outstandingBalanceRaw,
      } = {},
    } = {},
  } = state;

  const value = enhancedFeatureActive ? outstandingBalanceRaw : Number(outstandingBalanceRaw) >= 50 ?
    outstandingBalanceRaw :
    (Number(outstandingBalanceRaw) + 4.50).toFixed(2);

  const total = enhancedFeatureActive ? applePayExpressPaymentResponse?.result?.total : {
    label: 'John Lewis',
    amount: {
      currency: 'GBP',
      value,
    },
  };

  const modifiers = enhancedFeatureActive ? applePayExpressPaymentResponse?.result?.modifiers : [{
    supportedMethods: 'https://apple.com/apple-pay',
    data: {
      additionalShippingMethods: [{
        detail: 'Monday to Saturday, excluding public holidays 8am - 8pm',
        label: 'Standard UK delivery',
        amount: Number(basketTotalRaw) >= 50 ? '0.00' : '4.50',
        identifier: 'standardOneMan',
      }],
    },
  }];

  return {
    applePayMethod,
    details: {
      total,
      modifiers,
    },
    options: {
      requestPayerName: true,
      requestBillingAddress: true,
      requestPayerEmail: false,
      requestPayerPhone: true,
      requestShipping: true,
    },
  };
};

const paymentRequestPayload = (state, express, dispatch) => {
  if (express) return expressPaymentRequestPayload(state, dispatch);

  return {
    applePayMethod: get(state.config, 'applePayConfiguration.applePayMethod'),
    details: {
      total: {
        label: 'John Lewis',
        amount: {
          currency: 'GBP',
          value: get(state.orderForm, 'amounts.outstandingBalanceRaw'),
        },
      },
    },
    options: {
      requestPayerName: false,
      requestBillingAddress: true,
      requestPayerEmail: false,
      requestPayerPhone: true,
      requestShipping: false,
    },
  };
};

export const onShippingAddressChangeHandler = ({
  details,
  event,
  express,
  paymentRequest,
}) => async (dispatch, getState) => {
  event.updateWith(
    new Promise(async (resolve, reject) => {
      const state = getState();

      if (isFeatureActive(state, featureConstants.APPLE_PAY_EXPRESS_ENHANCED) && express) {
        const { city, country, postalCode } = paymentRequest?.shippingAddress || {};
        const partialAddress = {
          townOrCity: city,
          countryCode: country,
          ...postalCode && { postcode: `${postalCode}1AA` },
        };

        const setPartialDeliveryAddressResponse = await dispatch(setPartialDeliveryAddress(partialAddress));

        if (setPartialDeliveryAddressResponse?.type?.includes('FAILED')) {
          dispatch(handleExpressFailure());
          if (paymentRequest.abort) paymentRequest.abort();
          reject();
          return;
        }

        const paymentRequestPayloadResponse = await paymentRequestPayload(state, express, dispatch);
        const availableShippingMethods = paymentRequestPayloadResponse
          ?.details
          ?.modifiers
          ?.[0]
          ?.data
          ?.additionalShippingMethods || [];

        if (availableShippingMethods.length === 0) {
          dispatch(handleExpressFailure());
          if (paymentRequest.abort) paymentRequest.abort();
          reject();
          return;
        }

        resolve(paymentRequestPayloadResponse?.details);
      } else {
        resolve(details);
      }
    }),
  );
};

export const onShippingOptionChangeHandler = ({
  details,
  event,
  express,
  paymentRequest,
}) => async (dispatch, getState) => {
  event.updateWith(
    new Promise(async (resolve, reject) => {
      const state = getState();

      if (isFeatureActive(state, featureConstants.APPLE_PAY_EXPRESS_ENHANCED) && express) {
        const {
          delivery: {
            applePayExpressConfig,
            applePayEnhancedShippingIdentifier,
          } = {},
          orderForm: {
            amounts: {
              outstandingBalanceRaw,
            } = {},
          } = {},
        } = state;

        const availableShippingMethods = applePayExpressConfig?.modifiers?.[0]?.data?.additionalShippingMethods || [];
        if (availableShippingMethods.length === 0) {
          dispatch(handleExpressFailure());
          if (paymentRequest.abort) paymentRequest.abort();
          reject();
          return;
        }

        const selectedShippingMethod = availableShippingMethods.find(
          method => method?.identifier === paymentRequest.shippingOption,
        ) || availableShippingMethods[0];

        if (applePayEnhancedShippingIdentifier !== selectedShippingMethod?.identifier) {
          await dispatch({
            type: SET_APPLE_PAY_ENHANCED_SHIPPING_IDENTIFIER,
            applePayEnhancedShippingIdentifier: selectedShippingMethod?.identifier,
          });
        }

        const updatedValue = (Number(outstandingBalanceRaw) + Number(selectedShippingMethod?.amount)).toFixed(2);

        // set selected delivery method from the identifier provided by apple pay
        const updatedShippingMethods = availableShippingMethods.map(method => ({
          ...method,
          selected: method?.identifier === selectedShippingMethod.identifier,
        }));

        const updateObject = {
          total: {
            ...applePayExpressConfig?.total,
            amount: {
              ...applePayExpressConfig?.total?.amount,
              value: updatedValue,
            },
          },
          modifiers: [{
            supportedMethods: applePayExpressConfig?.modifiers[0]?.supportedMethods,
            data: {
              additionalShippingMethods: updatedShippingMethods,
            },
          }],
        };

        resolve(updateObject);
      } else {
        resolve(details?.total);
      }
    }),
  );
};

const applePayPaymentInitOnWeb = ({ express } = {}) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {
      applePayMethod,
      details,
      options,
    } = await paymentRequestPayload(state, express, dispatch);

    const paymentRequest = new window.PaymentRequest([applePayMethod], details, options);

    // allow access to the paymentRequest from PaymentWalletStubsModal
    if (env.isClientNonProd) window.mockPaymentRequest = paymentRequest;

    paymentRequest.onshippingaddresschange = async (event) => dispatch(onShippingAddressChangeHandler({
      event, details, paymentRequest, express,
    }));

    paymentRequest.onshippingoptionchange = async (event) => dispatch(onShippingOptionChangeHandler({
      event, details, paymentRequest, express,
    }));

    paymentRequest.onmerchantvalidation = (event) => {
      event.complete(merchantSessionPromise(event, dispatch, state, express));
    };

    dispatch(triggerOpenApplePayPaymentSheetEvent({ express }));

    await paymentRequest.show()
      .then(async (response) => { await dispatch(handlePaymentResponse({
        response,
        express,
      })); })
      .catch((error) => {
        dispatch(cancelApplePayPayment(error, express));
      });
  } catch (error) {
    dispatch(failureApplePayPayment(error, express));
  }
};

const applePayPaymentInitOnIosApp = () => async (dispatch, getState) => {
  try {
    const billingAddress = getBillingAddress(getState());
    const submitApplePayPaymentResponse = await dispatch(submitApplePayPayment(billingAddress));
    if (submitApplePayPaymentResponse?.type === `${APPLE_PAY_PAYMENT}.SUCCESS`) {
      window.webkit.messageHandlers.sendApplePayPaymentRequest.postMessage(paymentRequestPayload(getState()));
      dispatch(triggerOpenApplePayPaymentSheetEvent());
    } else {
      dispatch(failureApplePayPayment(submitApplePayPaymentResponse));
    }
  } catch (error) {
    dispatch(failureApplePayPayment(error));
  }
};

export const applePayPaymentInit = () => async (dispatch, getState) => {
  const state = getState();

  if (isWeb(state)) {
    await dispatch(applePayPaymentInitOnWeb());
  } else if (isIosApp(state)) {
    await dispatch(applePayPaymentInitOnIosApp());
  }
};

export const applePayExpress = () => async (dispatch) => {
  dispatch(setPaymentProcessing(true));
  dispatch(applePayPaymentInitOnWeb({ express: true }));
};

export const getApplePayExpressPaymentRequest = () => ({
  type: GET_APPLE_PAY_EXPRESS_PAYMENT_REQUEST,
  request: client => client({ path: URL_APPLE_PAY_EXPRESS_REQUEST, config: { method: 'GET' } }),
});
