
// Types
import { ScrollAnchorProps } from './ScrollAnchor.types';

const ScrollAnchor = ({ scrollId }: ScrollAnchorProps) => scrollId && (
  <div
    className="w-full"
    data-scroll={scrollId}
    data-testid="scroll-anchor"
  />
);

export default ScrollAnchor;
