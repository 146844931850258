import { createSelector } from 'reselect';

// Config
import { getAppOrderCompleteSelector } from '../../redux/reducers/app/appSelector';
import { getDeliveriesSelector } from '../../redux/reducers/delivery/deliverySelector';

const orderItemState = createSelector(
  [
    getAppOrderCompleteSelector,
    getDeliveriesSelector,
  ],
  (
    orderComplete,
    deliveries,
  ) => ({
    orderComplete,
    deliveries,
  }),
);

export default orderItemState;
