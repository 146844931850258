import { ChangeEvent } from 'react';

// Design System
import { LOQATE_ADDRESS_SEARCH } from 'jl-design-system/redux/actions/addressSearch/addressSearchActionConstants';

// Types
import { AppDispatch, AppGetState } from 'types/RootState.types';

// Config
import {
  ANALYTICS_LOQATE_TIMEOUT_ENTER_ADDRESS_CLICK,
  HIDE_SELECTED_BILLING_ADDRESS_PREVIEW,
  USE_DELIVERY_ADDRESS_AS_RESIDENTIAL_ADDRESS,
  USE_DIFFERENT_RESIDENTIAL_ADDRESS,
  RESET_ADDRESS_SEARCH,
  RESIDENTIAL_ADDRESS,
  USE_DELIVERY_ADDRESS_AS_BILLING_ADDRESS,
  USE_DIFFERENT_BILLING_ADDRESS,
} from '../../../constants/actionConstants';
import { sendNewRelicCustomEvent } from '../../../utils/logging/logging-utils';

export const onLoqateSearch =
  (
    response: { headers?: any; status?: any; url?: any },
    actionType: string = '',
    json?: {
      Items?: Array<{
        Cause?: string;
        Description?: string;
        Error?: string;
        PostalCode?: string;
      }>;
    },
  ) =>
    async (_dispatch: AppDispatch, getState: AppGetState) => {
      const state = getState();
      const orderFormId = state?.orderForm?.id;
      const sessionId = state?.bff?.sessionId;
      const timestamp = Date.now();
      const { userAgent } = window.navigator;
      const { status, url } = response;

      const postCode = json?.Items?.[0]?.PostalCode ?? '';
      const errorCode = json?.Items?.[0]?.Error ?? '';
      const errorCause = json?.Items?.[0]?.Cause ?? '';
      const errorDescription = json?.Items?.[0]?.Description ?? '';

      if (actionType !== `${LOQATE_ADDRESS_SEARCH}.SUCCESS`) {
        sendNewRelicCustomEvent('checkoutAppPostcodeSearch', {
          postCode,
          orderFormId,
          sessionId,
          status,
          timestamp,
          url,
          userAgent,
          errorCode,
          errorCause,
          errorDescription,
          'x-internal-duration': response.headers.get('x-internal-duration'),
          'x-response-size': response.headers.get('x-response-size'),
          'x-response-status': response.headers.get('x-response-status'),
        });
      }
      return {};
    };

export const onLoqateNetworkTimeoutCallback =
  (url: string) => async (_dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const orderFormId = state?.orderForm?.id;
    const sessionId = state?.bff?.sessionId;
    const timestamp = Date.now();
    const { userAgent } = window.navigator;

    sendNewRelicCustomEvent('checkoutAppPostcodeSearch', {
      errorDescription: 'No response from Loqate service',
      orderFormId,
      sessionId,
      timestamp,
      url,
      userAgent,
    });
    return {};
  };

export const onEnterManualAddress = () => async (_dispatch: AppDispatch, getState: AppGetState) => {
  const state = getState();
  const sessionId = state?.bff?.sessionId;
  const allLoqateCallsFailed = state?.app?.allLoqateCallsFailed ?? false;
  const timestamp = Date.now();
  const { userAgent } = window.navigator;

  sendNewRelicCustomEvent('manualAddressEntry', {
    sessionId,
    timestamp,
    userAgent,
    allLoqateCallsFailed,
  });
  return {};
};

export const triggerLoqateTimeoutEnterAddressManuallyAnalytics = () => ({
  type: ANALYTICS_LOQATE_TIMEOUT_ENTER_ADDRESS_CLICK,
});

export const hideSelectedBillingAddressPreview = () => ({
  type: HIDE_SELECTED_BILLING_ADDRESS_PREVIEW,
});

export const toggleResidentialAddress =
  (evt: ChangeEvent<HTMLInputElement>) => async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch({
      type: `${RESIDENTIAL_ADDRESS}_${RESET_ADDRESS_SEARCH}`,
    });

    if (evt?.target?.checked) {
      dispatch({
        type: USE_DELIVERY_ADDRESS_AS_RESIDENTIAL_ADDRESS,
        confirmedDeliveryAddress: getState()?.delivery?.confirmedDeliveryAddress,
      });
      return;
    }

    dispatch({
      type: USE_DIFFERENT_RESIDENTIAL_ADDRESS,
    });
  };

export const toggleUseDeliveryAsBillingAddress = (evt: ChangeEvent<HTMLInputElement>) => ({
  type: evt?.target?.checked ? USE_DELIVERY_ADDRESS_AS_BILLING_ADDRESS : USE_DIFFERENT_BILLING_ADDRESS,
});
