// Types
import { AppDispatch, RootState, AppGetState } from 'types/RootState.types';

// Config
import { MOB, DROID, WEB } from '../../constants/applicationTypeConstants';
import loginTypeConstants from '../../constants/loginTypeConstants';
import { isApps } from '../../redux/reducers/app/appReducer';

const getMvtFeatures = (state: RootState): {} => {
  const features = state?.config?.features ?? [];
  const mvtFeatures = features?.filter(feature => feature.source === 'MVT');
  return mvtFeatures.reduce((acc, feature) => ({ ...acc, [feature.id]: feature.enabled }), {});
};

export const logToConsole = (response: any): void => {
  if (!window.publicJLSiteDomain?.includes('johnlewis')) {
    /* eslint-disable no-console */
    console.log(response);
  }
};

export function stringifyObject(object = {}): string {
  return JSON.stringify(object);
}

export function setUserInformationToNewRelic(state: RootState): void {
  if (window.newrelic && window.newrelic.setCustomAttribute) {
    const sessionId = state?.bff?.sessionId ?? '';
    const isAppOrder = isApps(state);
    const isGuest = state?.user?.isGuest;
    const applicationType = state?.app?.applicationType;

    let loginType = state?.login?.loginType;

    if (isAppOrder) {
      loginType = loginTypeConstants.CUSTOMER_APPS;
    }

    const experiences = stringifyObject({
      ...getMvtFeatures(state),
    });

    // TODO change applicationTypeConstants in conjunction with backend to be consistent and better named
    const applicationTypes = new Map();
    applicationTypes.set(DROID, 'android');
    applicationTypes.set(MOB, 'ios');
    applicationTypes.set(WEB, 'web');

    // booleans must be sent as strings
    window.newrelic.setCustomAttribute('sessionId', sessionId);
    window.newrelic.setCustomAttribute('isGuest', `${isGuest}`);
    window.newrelic.setCustomAttribute('experiences', `${experiences}`);
    if (loginType) window.newrelic.setCustomAttribute('loginType', loginType);
    if (applicationType) window.newrelic.setCustomAttribute('applicationType', applicationTypes.get(applicationType));
  }
}

export function sendNewRelicCustomEvent(actionName: string, attributes?: {}): void {
  if (window.newrelic && window.newrelic.addPageAction) {
    window.newrelic.addPageAction(actionName, attributes);
  }
}

export function createNewRelicTrackingObject(): any {
  if (window.newrelic && window.newrelic.interaction) {
    return window.newrelic.interaction();
  }
  return false;
}

export function saveNewRelicTrackingEvent(trackingObject: { save: () => void }): void {
  if (trackingObject) {
    trackingObject.save();
  }
}

export function endNewRelicTrackingEvent(trackingObject: { end: () => void }): void {
  if (trackingObject) {
    trackingObject.end();
  }
}

export function setCorrelationIdToNewRelic(id: string): void {
  if (window.newrelic && window.newrelic.interaction) {
    const newRelicInteraction = window.newrelic.interaction();
    newRelicInteraction.setAttribute('correlationId', id);
  }
}

export const reportErrorToNewRelic = (
  { error, errorDescription, options = {} }:
  { error?: {}; errorDescription?: string; options?: {} },
) => (_dispatch: AppDispatch, getState: AppGetState): void => {
  if (window.newrelic && window.newrelic.noticeError) {
    const sessionId = getState()?.bff?.sessionId;
    const timestamp = Date.now();
    const { pathname } = window.location;
    const { userAgent } = window.navigator;

    const params = {
      timestamp,
      path: (pathname || '').replace(/(\?.*)|(#.*)/g, ''),
      userAgent,
      sessionId,
      errorDescription,
      ...options,
    };

    window.newrelic.noticeError(error, params);
  }
};

export const initLogging = () => (dispatch: AppDispatch, getState: AppGetState): any => {
  setUserInformationToNewRelic(getState());
  return dispatch({ type: 'INIT_LOGGING' });
};
