import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Design System
import validate from 'jl-design-system/form/validation/validate';

// Config
import { postPayments } from './paymentActions';
import { recordImpressions } from '../app/appActions';
import { getAndSelectNewCollectionDate } from '../click-and-collect/clickAndCollectActions';
import {
  putDeliveryDetails,
  putDeliveryAddress,
  getDeliveryMethods,
  initDelivery,
} from '../delivery/deliveryActions';
import { isApps } from '../../reducers/app/appReducer';
import { isFeatureActive } from '../../reducers/config/configReducer';
import deliveryConstants, { fulfilmentTypes } from '../../../constants/deliveryConstants';
import featureConstants from '../../../constants/featureConstants';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';
import {
  SHOW_EXPRESS_PAYMENTS,
  EXPRESS_PAYMENTS_FAILED,
  INIT_EXPRESS_PAYMENT,
  EXPRESS_PAYMENTS_VALIDATION_FAILED,
  SET_PARTIAL_DELIVERY_ADDRESS,
  SET_FULL_DELIVERY_ADDRESS,
  POST_DELIVERY_PAGE,
} from '../../../constants/actionConstants';
import {
  URL_SET_PARTIAL_DELIVERY_ADDRESS,
  URL_SET_FULL_DELIVERY_ADDRESS,
  URL_POST_DELIVERY_PAGE,
} from '../../../constants/endpointConstants';
import {
  getFormattedAddressFromApplePay,
  countryCodeIsInternational,
} from '../../../utils/address/addressHelpers';
import { getDeliveryAddressFormConfig } from '../../../utils/form/configs/deliveryAddress';

export const handleExpressFailure = response => ({
  type: EXPRESS_PAYMENTS_FAILED,
  response,
});

export const isFailure = action => action?.type.includes('FAILED');

export const handleApplePayExpress = ({
  shippingContact = {},
  payerPhone,
  selectedShippingOptionIdentifier,
} = {}) => async (dispatch, getState) => {
  dispatch({
    type: INIT_EXPRESS_PAYMENT,
  });

  const formattedAddress = getFormattedAddressFromApplePay(shippingContact, payerPhone);

  if (countryCodeIsInternational(formattedAddress.address?.countryCode)) {
    return dispatch(handleExpressFailure({
      type: EXPRESS_PAYMENTS_VALIDATION_FAILED,
    }));
  }

  // validate shipping address
  const validateAddressResult = validate({
    ...formattedAddress.address,
    ...formattedAddress.addressee,
    phoneNumber: formattedAddress.phoneNumber,
  }, {
    config: getDeliveryAddressFormConfig({
      enableGBCounty: !!formattedAddress.address?.countyStateOrProvince,
    }),
  });

  if (!isEmpty(validateAddressResult)) {
    return dispatch(handleExpressFailure({
      validateAddressResult,
      type: EXPRESS_PAYMENTS_VALIDATION_FAILED,
    }));
  }

  const body = {
    addressDetails: {
      ...formattedAddress,
    },
  };

  let fulfilmentOfferId;

  if (selectedShippingOptionIdentifier) {
    // presence of selectedShippingOptionIdentifier tell us we're in the enhanced delivery options journey
    fulfilmentOfferId = selectedShippingOptionIdentifier;

    const setFullDeliveryAddressResponse = await dispatch(setFullDeliveryAddress({ body }));
    if (isFailure(setFullDeliveryAddressResponse)) {
      return dispatch(handleExpressFailure(setFullDeliveryAddressResponse));
    }
  } else {
    // legacy journey that forces standard delivery
    const putDeliveryAddressResponse = await dispatch(putDeliveryAddress({ body, express: true }));
    if (isFailure(putDeliveryAddressResponse)) return dispatch(handleExpressFailure(putDeliveryAddressResponse));

    const id = get(putDeliveryAddressResponse, 'result.orderForm.deliveries[0].id');

    const methodsResponse = await dispatch(getDeliveryMethods({ express: true, id }));
    if (isFailure(methodsResponse)) return dispatch(handleExpressFailure(methodsResponse));

    const standardDeliveryMethod = methodsResponse?.result?.methods?.find(
      m => m.fulfilmentType === fulfilmentTypes.ONE_PERSON_STANDARD,
    ) ?? {};

    fulfilmentOfferId = standardDeliveryMethod.fulfilmentOffers?.fulfilmentOfferId;
  }

  const {
    delivery: {
      deliveries = [],
    } = {},
  } = getState() ?? {};

  const deliveryDetailsPayload = [{
    deliveryId: deliveries[0]?.id,
    fulfilment: {
      fulfilmentOfferId,
    },
  }];

  const putDeliveryDetailsResponse = await dispatch(putDeliveryDetails({ deliveryDetailsPayload, express: true }));
  if (isFailure(putDeliveryDetailsResponse)) return dispatch(handleExpressFailure(putDeliveryDetailsResponse));

  const postPaymentsResponse = await dispatch(postPayments());
  if (isFailure(postPaymentsResponse)) return dispatch(handleExpressFailure(postPaymentsResponse));

  return postPaymentsResponse;
};

export const showExpressPayments = ({
  isApplePayExpressEnabled = false,
  isPayPalPayExpressEnabled = false,
} = {}) => {
  const shouldShow = isApplePayExpressEnabled || isPayPalPayExpressEnabled;

  return ({
    type: SHOW_EXPRESS_PAYMENTS,
    shouldShow,
    isApplePayExpressEnabled,
    isPayPalPayExpressEnabled,
    ...shouldShow && {
      payOptionsAnalytics: {
        ...(isApplePayExpressEnabled && {
          [paymentTypeConstants.APPLE_PAY_EXPRESS]: {
            default: 1,
            enabled: 1,
          },
        }),
        ...(isPayPalPayExpressEnabled && {
          [paymentTypeConstants.PAYPAL_EXPRESS]: {
            default: 1,
            enabled: 1,
          },
        }),
      },
    },
  });
};

export const shouldShowExpressApplePay = ({ dispatch, state, isSingleBatchOneMan, isSingleBatch }) => {
  const {
    config: {
      features = [],
    } = {},
    user: {
      collectionPointsSaved,
      isGuest,
    } = {},
    payment: {
      applePayCompatibilityChecksComplete,
    } = {},
  } = state ?? {};

  if (!applePayCompatibilityChecksComplete) return false;

  const expressApplePaySignedInFeature = features?.find(
    feature => feature.id === featureConstants.APPLE_PAY_EXPRESS_SIGNED_IN,
  ) ?? {};

  const applePayExpressEnhancedFeatureEnabled = isFeatureActive(state, featureConstants.APPLE_PAY_EXPRESS_ENHANCED);

  const basketCondition = applePayExpressEnhancedFeatureEnabled ? isSingleBatch : isSingleBatchOneMan;

  const applePayExpressSignedInFeatureEnabled = isFeatureActive(state, featureConstants.APPLE_PAY_EXPRESS_SIGNED_IN);
  const expressApplePaySignedInFeatureImpressionId = expressApplePaySignedInFeature?.impressionId;

  let customerConditionsMet = false;

  if (isGuest) {
    customerConditionsMet = basketCondition;
  } else {
    if (applePayExpressSignedInFeatureEnabled && !collectionPointsSaved) {
      customerConditionsMet = basketCondition;
    }
  }

  const shouldRecordImpressionId = expressApplePaySignedInFeatureImpressionId &&
    !isGuest &&
    !collectionPointsSaved &&
    basketCondition;

  if (shouldRecordImpressionId) dispatch(recordImpressions(expressApplePaySignedInFeatureImpressionId));

  return customerConditionsMet;
};

export const shouldShowExpressPayPal = ({ dispatch, state, isSingleBatch }) => {
  const {
    user: { isGuest } = {},
    payment: {
      payPalPayExpressCompatibilityChecksComplete: isPayPalPayExpressEnabled,
      payPalServiceUnavailable,
      getPayPalClientTokenCallActive,
    } = {},
    config: {
      features = [],
    } = {},
  } = state ?? {};

  const customerConditionsMet = isGuest && isSingleBatch;
  const clientTokenCallInactive = getPayPalClientTokenCallActive === false
    || getPayPalClientTokenCallActive === undefined;
  const serviceAvailable = payPalServiceUnavailable === false
    || payPalServiceUnavailable === undefined;

  const customerCouldUseFeature = customerConditionsMet && clientTokenCallInactive && serviceAvailable;

  if (customerCouldUseFeature) {
    const expressPayPalFeature = features?.find(
      feature => feature.id === featureConstants.ENABLE_PAYPAL_EXPRESS,
    ) ?? {};

    const expressPayPalFeatureImpressionId = expressPayPalFeature.impressionId;
    if (expressPayPalFeatureImpressionId) dispatch(recordImpressions(expressPayPalFeatureImpressionId));
  }

  return !!(customerCouldUseFeature && isPayPalPayExpressEnabled);
};

export const shouldShowExpressPaymentMethods = () => (dispatch, getState) => {
  const state = getState() ?? {};

  const {
    delivery: {
      deliveries = [],
    } = {},
    orderForm: {
      getPaymentPageComplete,
    } = {},
  } = state ?? {};

  const isSingleBatch = deliveries.length === 1;
  const isSingleBatchOneMan = isSingleBatch && deliveries.some(delivery => delivery.type === deliveryConstants.ONE_MAN);
  const isSpecialOrder = deliveries.some(delivery => delivery.type === deliveryConstants.SPECIAL_ORDER);
  const isUndeliverable = deliveries.some(delivery => delivery.type === deliveryConstants.UNDELIVERABLE);

  const isApplePayExpressEnabled = shouldShowExpressApplePay({ dispatch, state, isSingleBatchOneMan, isSingleBatch });
  const isPayPalPayExpressEnabled = shouldShowExpressPayPal({ dispatch, state, isSingleBatch });

  if (
    isApps(state)
    || (!isApplePayExpressEnabled && !isPayPalPayExpressEnabled)
    || getPaymentPageComplete
    || isSpecialOrder
    || isUndeliverable
  ) return dispatch(showExpressPayments());

  return dispatch(showExpressPayments({ isApplePayExpressEnabled, isPayPalPayExpressEnabled }));
};

export const setPartialDeliveryAddress = (payload) => ({
  type: SET_PARTIAL_DELIVERY_ADDRESS,
  request: (client) => client({
    path: URL_SET_PARTIAL_DELIVERY_ADDRESS,
    config: {
      method: 'POST',
      body: payload,
    },
  }),
});

export const setFullDeliveryAddress = ({ body }) => ({
  type: SET_FULL_DELIVERY_ADDRESS,
  request: client => client({ path: URL_SET_FULL_DELIVERY_ADDRESS, config: { method: 'POST', body } }),
  address: body.addressDetails,
});

export const cleanupExpressBasket = () => async (dispatch, getState) => {
  const {
    clickAndCollect: {
      selectedCollectionPoint,
    } = {},
    delivery: {
      confirmedDeliveryAddress,
      selectedDeliveryChoiceId,
    } = {},
  } = getState() ?? {};

  const hasSelectedCollectionPoint = selectedDeliveryChoiceId === deliveryConstants.CLICK_AND_COLLECT
    && selectedCollectionPoint;

  const hasSelectedDeliveryAddress = selectedDeliveryChoiceId === deliveryConstants.DELIVERY
    && confirmedDeliveryAddress;

  await dispatch({
    type: POST_DELIVERY_PAGE,
    request: client => client({ path: URL_POST_DELIVERY_PAGE, config: { method: 'POST' } }),
  });

  if (hasSelectedDeliveryAddress) {
    dispatch(initDelivery());
  } else if (hasSelectedCollectionPoint) {
    dispatch(getAndSelectNewCollectionDate());
  }
};
